// Tools

export const regex = {
	reAddress: [/^([^\s]*\d+\s*[\+\-\*\/]\s*\d+\s*)/, ' $1'],
	reAddressSymbols: /[\.,]+/g,
	reBrands: /['"\.,\/#!\$%\^&\*;:{}=\-_`~\(\)\? ]/g,
	reCsvComma: /,/g,
	reCsvQuote: /"/g,
	reDigits: /^\d+$/,
	rePOBox: /((p[\s\.,-]*o[\s\.,-]*)?b[\s\.,-]*(o[\s\.,-]*)?x[\s\.,-]*\d+|p[\s\.,-]*o[\s\.,-]*(b[\s\.,-]*)?\d+)/i,
	reLockedBag: /lock(ed)?\s*(bag|[0-9]+)|bag *[0-9]+/i,
	reMyPost: /mypost/i,
	reParcelLocker: /(locker|(parcel\s*)collect)/i,
	rePostOffice: /post *office|(post|(p|g)mb) *[0-9]+/i,
	reSearch: /[-_\s]/g,
	reSpace: /\s+/g,
	reSymbols: /^[\!\@\#\$\%\^&\*\-\+_\/]/,
	reVariation: /\[(.*?)\]$/,
}

export function base64ToBlob(base64, mime) {
	mime = mime || '';
	let sliceSize = 1024;
	let byteChars = window.atob(base64), byteArrays = [];

	for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
		let slice = byteChars.slice(offset, offset + sliceSize);
		let byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		byteArrays.push(new Uint8Array(byteNumbers));
	}
	return new Blob(byteArrays, {type: mime});
}

export function copyToClipboard(text = '') {
	if (!text) return false;
	let textarea = document.createElement('textarea');
	textarea.style.border = 'none';
	textarea.style.outline = 'none';
	textarea.style.boxShadow = 'none';
	textarea.style.background = 'transparent';
	textarea.value = text;
	document.body.appendChild(textarea);
	textarea.select();
	document.execCommand('copy');
	document.body.removeChild(textarea);
	return true;
}

export function datePad(value) {
	return ('00'+value).slice(-2);
}

export function getCaretPosition(el) {
	let range = window.getSelection().getRangeAt(0);
	let preCaretRange = range.cloneRange();
	preCaretRange.selectNodeContents(el);
	preCaretRange.setEnd(range.endContainer, range.endOffset);
	return preCaretRange.toString().length;
}

export function getDateValue(date, time = false, timezone = false, utc = false) {
	if (utc) {
		return date.getUTCFullYear().toString()+datePad(date.getUTCMonth()+1)+datePad(date.getUTCDate()) + (time ? '-'+datePad(date.getUTCHours())+datePad(date.getUTCMinutes())+datePad(date.getUTCSeconds()) : '') + (timezone ? date.getTimezoneOffset() : '');
	}
	else {
		return date.getFullYear().toString()+datePad(date.getMonth()+1)+datePad(date.getDate()) + (time ? '-'+datePad(date.getHours())+datePad(date.getMinutes())+datePad(date.getSeconds()) : '') + (timezone ? date.getTimezoneOffset() : '');
	}
}

export async function getFont(filename) {
	try {
		return await (await fetch(filename)).arrayBuffer();
	}
	catch (e) {
		return null;
	}
}

export function getQueryValue(q) {
	let qs = window.location.search.substring(1).split('&');
	for (let i = 0; i < qs.length; i++) {
		let p = qs[i].split('=');
		if (p[0] == q) return p[1];
	}
	return null;
}

export function hashCode(str) {
	let hash = 0, i, chr;
	for (i = 0; i < str.length; i++) {
		chr = str.charCodeAt(i);
		hash = ((hash << 5) - hash) + chr;
		hash |= 0; // Convert to 32-bit integer
	}
	return Math.abs(hash);
}

export function hasKey(obj, keys, excludedKeys = null) {
	for (let key in keys) {
		if (keys.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
			if (excludedKeys !== null) {
				for (let excludedKey in excludedKeys) {
					if (keys.hasOwnProperty(excludedKey)) {
						return false;
					}
				}
			}
			return true;
		}
	}
	return false;
}

export function htmlDecode(input) {
	return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
}

// Check if all search values are present in a string
export function includesAll(str, values) {
	if (typeof str !== 'string') str = str.toString();
	if (typeof values === 'string') {
		values = [values];
	}
	for (let value of values) {
		if (!str.includes(value)) {
			return false;
		}
	}
	return true;
}

// Get locale date and time
export function localeDateTime(date, locale, options) {
	return date.toLocaleString(locale, options)+' '+date.toLocaleString(locale, {timeZoneName: 'short'}).split(' ').pop();
}

export function round2(num) {
	return (+(Math.round(num + 'e+2') + 'e-2')).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

// Select element content
export function selectElement(el, start = null, end = null) {
	if (el.textContent.length) {
		setTimeout(() => {
			let selection = window.getSelection();
			let range = document.createRange();
			range.selectNodeContents(el);

			if (start !== null) {
				range.setStart(el.childNodes[0], start);
				if (end !== null) {
					range.setEnd(el.childNodes[0], end);
				}
			}

			selection.removeAllRanges();
			selection.addRange(range);
		}, 1);
	}
	else {
		el.focus();
	}
}

// Select text
export function selectText() {
	window.getSelection().selectAllChildren(this);
}

export function usernamePart(s) {
	return s.substr(0, 3);
}

export function weightedRandom(data) {
	let entry, sum = 0, r = Math.random();
	for (entry of data) {
		sum += parseFloat(entry[0]);
		if (r <= sum) return entry[1];
	}
	return null;
}


export function addListener(els, events, func) {
	let reSpace = /\s+/;
	events = events.split(reSpace);
	for (let el of document.querySelectorAll(els)) {
		for (let event of events) {
			el.addEventListener(event, func);
		}
	}
}

export function removeListener(els, events, func) {
	let reSpace = /\s+/;
	els = document.querySelectorAll(els);
	events = events.split(reSpace);
	for (let el of els) {
		for (let event of events) {
			el.removeEventListener(event, func);
		}
	}
}

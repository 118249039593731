// API Client

import Config from './config.js';

export default class APIClient {
	constructor(host) {
		this.host = host;
		this.reset();
	}

	reset() {
		this.user = {
			id: '',
			username: '',
			firstname: '',
			lastname: '',
			permissions: [],
			silent: false,
			adminActions: false,
			token: '',
			result_code: '',
			reponseStatus: 0,
		}
	}

	async get(api, params = null, useAuth = true, headers = {}) {
		try {
			let query = '';
			if (params) {
				let paramArr = [];
				for (let param in params) {
					if (Array.isArray(params[param])) {
						// Array
						for (let value of params[param]) {
							paramArr.push(param + '[]=' + encodeURIComponent(value));
						}
					}
					else {
						// Value
						paramArr.push(param + '=' + encodeURIComponent(params[param]));
					}
				}
				query = '?' + paramArr.join('&');
			}

			let resOptions = {
				headers: headers,
			};
			if (useAuth) Object.assign(resOptions.headers, this.authHeader());

			let response = await fetch(this.host + api + query, resOptions);

			if (useAuth) {
				// Update token
				let token = response.headers.get('access-token');
				if (token) this.user.token = token;
			}
			return response;
		}
		catch (e) {
			console.log(e);
			throw Error;
		}
	}

	async delete(api, params = null, useAuth = true, headers = {}) {
		return this.postData(api, params, useAuth, headers, 'delete');
	}

	async post(api, params = null, useAuth = true, headers = {}) {
		return this.postData(api, params, useAuth, headers, 'post');
	}

	async put(api, params = null, useAuth = true, headers = {}) {
		return this.postData(api, params, useAuth, headers, 'put');
	}

	async postData(api, params, useAuth, headers, method) {
		try {
			let formData = null;
			if (params) {
				formData = new FormData();
				for (let param in params) {
					if (Array.isArray(params[param])) {
						// Array
						for (let value of params[param]) {
							formData.append(param + '[]', value);
						}
					}
					else if (typeof params[param] == 'object' && 'file' in params[param] && 'data' in params[param].file && 'filename' in params[param].file) {
						// File with filename
						formData.append(param, params[param].file.data, params[param].file.filename);
					}
					else {
						// Value
						formData.append(param, params[param]);
					}
				}
			}

			let resOptions = {
				method: method,
				headers: headers,
				body: formData,
			};
			if (useAuth) Object.assign(resOptions.headers, this.authHeader());

			let response = await fetch(this.host + api, resOptions);

			if (useAuth) {
				// Update token
				let token = response.headers.get('access-token');
				if (token) this.user.token = token;
			}

			return response;
		}
		catch (e) {
			console.log(e);
			throw Error;
		}
	}

	async login(options = {}) {
		let userToken = null;
		if (!options.username || !options.password) {
			// Use token
			userToken = localStorage.getItem('usertoken');
			if (!userToken) return false;
		}

		try {
			let formData = new FormData();
			if (options.username) formData.append('username', options.username);
			if (options.password) formData.append('password', options.password);

			let resOptions = {method: 'post', body: formData};
			if (userToken) resOptions.headers = this.authHeader(userToken);

			let response = await fetch(this.host + Config.API_ROUTE.USERS.LOGIN, resOptions);
			let data = await response.json();

			this.user.result_code = data.result_code;
			this.user.reponseStatus = response.status;

			if (!response.ok) {
				console.error(data.result_code, data.result);
				return false;
			}

			if (data.result == 'success') {
				if (!options.permissions || options.permissions.some(perm => data.user.permissions.includes(perm))) {
					// Save user details
					this.user.id = data.user.id;
					this.user.username = data.user.username;
					this.user.firstname = data.user.firstname;
					this.user.lastname = data.user.lastname;
					this.user.permissions = data.user.permissions;
					this.user.silent = data.user.permissions.includes(Config.USER_PERMISSION.SILENT);
					this.user.adminActions = data.user.permissions.includes(Config.USER_PERMISSION.ADMIN_ACTIONS);
					this.user.token = data.user.token;
					localStorage.setItem('usertoken', this.user.token);
					return true;
				}
				else {
					console.log('Error: Insufficient user permissions.');
				}
			}
			else {
				console.log(data.result_code, data.result);
			}
		}
		catch (e) {
			console.log('Error: Could not log into the server.');
		}

		return false;
	}

	logout() {
		localStorage.removeItem('usertoken');
		this.reset();
		return true;
	}

	authHeader(token = null) {
		return {'Authorization': 'Bearer ' + (token || this.user.token)};
	}
}
//  Discount Chemist
//  Order System Home

import APIClient from '../../common/js/api-client.js';
import Config from '../../common/js/config.js';
import i18n from '../../common/js/i18n.js';
import NotificationBar from '../../common/js/notification.js';
import {addListener} from '../../common/js/tools.js';

window.api = new APIClient(Config.API_SERVER),
window.page = {
	type: null,
	els: {
		username: null,
		password: null,
	},
	notification: new NotificationBar({positionFixed: true}),
	local: location.hostname.startsWith('local.') || location.hostname.startsWith('staging.'),
}

// Load store config
let loadStoreConfigRequest = Config.loadStoreConfig();

// Login
let loginRequest = api.login();


document.addEventListener('DOMContentLoaded', async function() {
	// Load store config
	await loadStoreConfigRequest;

	// Load localisation data
	try {
		i18n.load(Config.StoreConfig.homeLocale);
		i18n.process(document.body);
	}
	catch (e) {
		page.notification.show('Error: Could not load localisation data.', {background: 'bg-red', hide: false});
	}

	// Page elements
	page.els.username = document.getElementById('page-username');
	page.els.password = document.getElementById('page-password');

	// Page type
	let pageTypeName = document.getElementById('container');
	if (pageTypeName) pageTypeName = pageTypeName.dataset.pageType;

	for (let pageType in Config.PAGE_TYPE) {
		if (Config.PAGE_INFO[Config.PAGE_TYPE[pageType]].name == pageTypeName) {
			page.type = Config.PAGE_TYPE[pageType];
			break;
		}
	}

	if (!page.type) {
		page.notification.show(i18n.get('home.notifications.invalid_page_type'), {hide: false});
		return;
	}

	switch (page.type) {
		case Config.PAGE_TYPE.HOME:
			// Check login
			if (await loginRequest) {
				let name = api.user.firstname + (api.user.lastname ? ' '+api.user.lastname : '')
				let username = document.getElementById('username');
				username.textContent = name;
				username.classList.remove('hide');
				document.getElementById('logged-in-username').textContent = name;
				document.getElementById('logged-in').classList.remove('hide');
				document.getElementById('nav-menu-list').classList.remove('hide');
				document.getElementById('nav-logout').classList.remove('hide');

				for (let button of document.querySelectorAll('#home button[data-page]')) {
					if (button.dataset?.permissions) {
						let permissions = button.dataset.permissions.split(',');
						button.classList[permissions.every(perm => api.user.permissions.includes(Config.USER_PERMISSION[perm])) ? 'remove' : 'add']('hide');
					}
				}
			}
			else {
				// Username
				page.els.username.value = localStorage.getItem('username') || '';
				page.els.password.value = '';
				document.getElementById('login').classList.remove('hide');
				page.els[page.els.username.value ? 'password' : 'username'].focus();
			}
			break;
	}

	let pageInfo = Config.PAGE_INFO[page.type];


	// Title, header
	document.title = `${Config.StoreConfig.system_name} - ${i18n.get(pageInfo.title)}`;
	let header = document.getElementById('header');
	let headerTitle = header.querySelector('.title');
	headerTitle.textContent = i18n.get(pageInfo.heading, {STORE_NAME: Config.StoreConfig.system_name}) || document.title;
	if (pageInfo.position) {
		headerTitle.classList.add(pageInfo.position);
	}

	// Header colour
	if (window.location.hostname.startsWith('local.')) {
		document.getElementById('header').classList.add('local');
	}


	// Toggle menu
	let els = [['nav-menu', '#nav-menu', '#nav-menu-list ul']];
	for (let el of els) {
		let toggle = function(show = null) {
			let button = document.querySelector(el[1]);
			let list = document.querySelector(el[2]);

			if (show === null) {
				// Toggle
				button.classList.toggle('selected');
				list.classList.toggle('hide');
			}
			else if (show) {
				// Show
				button.classList.add('selected');
				list.classList.remove('hide');
			}
			else {
				// Hide
				button.classList.remove('selected');
				list.classList.add('hide');
			}
		}

		document.getElementById(el[0]).addEventListener('click', function(e) {
			e.stopPropagation();
			toggle();
		});

		window.addEventListener('click', function(e) {
			// Hide
			if (!document.querySelector(el[2]).classList.contains('hide')) {
				toggle(false);
			}
		});
	}

	document.getElementById('nav-logout').addEventListener('click', function() {
		api.logout();
		window.location.href = '/';
	});


	// Page buttons
	addListener('#home button[data-page]', 'click', async function(e) {
		e.preventDefault();
		let username = page.els.username ? page.els.username.value.toLowerCase() : null;
		let password = page.els.password ? page.els.password.value : null;
		let success = false;

		if (api.user.token || e.target.dataset?.nologin) {
			success = true;
		}
		else if (username && password) {
			api.logout();

			if (await api.login({username: username, password: password})) {
				// Save user ID and username
				localStorage.setItem('username', api.user.username);
				localStorage.setItem('usertoken', api.user.token);
				success = true;
			}
			else {
				page.notification.show(i18n.get(api.user.result_code));
			}
		}
		else {
			page.notification.show(i18n.get('home.notifications.enter_username_password'));
		}

		if (!success) return;

		// Go to page
		if (e.target.dataset?.page) {
			window.location.href = e.target.dataset.page;
		}
	});

	if (navigator.serviceWorker) {
		navigator.serviceWorker.register('/pwa.js').catch((function(){}));
	}
});

// Internationalisation

import Config from './config.js';
import i18nData from '../i18n/index.js';

class I18N {
	constructor(url) {
		this.url = url;
		this.language = null;
		this.languages = {};
	}

	get(value, replacements) {
		let langData = this.languages[this.language];
		if (!langData || value === undefined) return value;

		let id = value;
		let data = null;
		let dataPos = value.indexOf('::');

		if (dataPos > 0 && value.length >= dataPos + 2) {
			id = value.slice(0, dataPos);
			data = value.slice(dataPos + 2).split('::');
		}

		let langLine = this.getLine(id, langData);

		if (typeof langLine === 'object') {
			return '';
		}

		if (data) {
			for (let dataValue of data) {
				langLine = langLine.replace('{{}}', this.getLine(dataValue, langData) || dataValue);
			}
		}

		if (typeof replacements === 'object') {
			for (let id in replacements) {
				let replacement = replacements[id];
				if (replacement) {
					langLine = langLine.replace(`{{${id}}}`, this.getLine(replacement, langData) || replacement);
				}
			}
		}
		return langLine;
	}

	getLine(id, langData) {
		let line = null;
		id = id.toString();

		if (id.includes('.')) {
			for (let part of id.split('.')) {
				if (typeof langData !== 'object' || !(part in langData)) {
					langData = id;
					break;
				}
				langData = langData[part];
			}
			line = langData;
		}
		else if (langData[id] && typeof langData[id] !== 'object') {
			line = langData[id];
		}
		else if (langData.api && langData.api[id]) {
			line = langData.api[id];
		}
		else {
			line = id;
		}
		return line;
	}

	load(id = 'en-AU') {
		this.language = (id in i18nData) ? id : 'en-AU';
		this.languages[id] = i18nData[this.language];
		return true;
	}

	process(el) {
		if (el.dataset && el.dataset.i18n) {
			let text = this.get(el.dataset.i18n);
			if (text) {
				let attr = el.dataset.i18nAttr;
				if (attr) {
					el.setAttribute(attr, text);
					el.removeAttribute('data-i18n-attr');
				}
				else {
					el.innerHTML = text;
				}
			}
			el.removeAttribute('data-i18n');
		}

		if (el.children && el.children.length) {
			for (let child of el.children) {
				this.process(child);
			}
		}
	}
}

export default new I18N(Config.I18N_URL);

//  Discount Chemist
//  Order System Configuration

import JSON5 from 'json5';

console.log(ENV);

const Config = {};

{
	// API
	let id = window.location.hostname.split('.', 1)[0];
	// process.env.
	Config.CONFIG_URL = process.env.CONFIG_URL //'https://'+id+'.config.service.discountchemist.com.au/';
	Config.API_SERVER = process.env.API_SERVER//'http://localhost:8001/api/'; //'https://'+id+'.api.service.discountchemist.com.au/api/';
	Config.WS_SERVER = process.env.WS_SERVER //"127.0.0.1:8002"; //id+'.ws.service.discountchemist.com.au';
	Config.I18N_URL = process.env.I18N_URL // '/common/i18n/';

	Config.API_ROUTE = {
		// General
		EMAIL_SENDER: 'email-sender',

		// Analytics
		ANALYTICS: {
			SKU: {
				GET: 'stats/skus',
			},
		},

		// Management
		CONFIG: {
			GET: 'config/get',
			SAVE: 'config/save',
		},
		USERS: {
			GET: 'users/get',
			LOGIN: 'users/login',
			PASSWORD: 'users/password',
			REMOVE: 'users/remove',
			SAVE: 'users/save',
		},

		// Orders
		ORDERS: {
			ADD_COLLECT: 'orders/addcollect',
			BATCH: 'orders/batch',
			GET: 'orders/get',
			DOWNLOAD: 'orders/download',
			LOAD: 'orders/load',
			REMOVE: 'orders/remove',
			SAVE: 'orders/save',
			TRACKING: {
				CREATE: 'orders/consignment/create',
				GET: 'orders/tracking/get',
				SAVE: 'orders/tracking/save',
				UPLOAD: 'orders/tracking/upload',
			},
			UNSENT: {
				GET: 'orders/unsent/get',
				MARK_SENT: 'orders/unsent/marksent',
			},
			UPDATE: 'orders/update',
		},
		ORDER: {
			GET: 'order/get',
			DATA: 'order/data',
			HISTORY: 'order/history',
		},

		// Packing
		PACK: {
			GET: 'pack/get',
			OWN_STATS: 'pack/stats/own',
			STATS: 'pack/stats',
		},

		// Products
		LOAD_BRANDS: 'brands',

		// Representatives
		REPRESENTATIVES: {
			GET: 'representatives',
			REMOVE: 'representatives',
			SAVE: 'representatives',
		},

		// SKU
		SKUS: {
			GET: 'sku/get',
			INVOICES: 'sku/invoices',
			UPDATE: 'sku/update',
		},

		// StreetPricer
		STREETPRICER: {
			CSV: {
				GET: 'streetpricer/csv',
				IMPORT: 'streetpricer/csv/import',
			},
		},

		// Stock
		STOCK: {
			UPDATE: 'stock/update',
		},
	};
}


// Store configuration
Config.StoreConfig = {};
Config.loadStoreConfig = async () => {
	try {
		Config.StoreConfig = JSON5.parse(await (await fetch(Config.CONFIG_URL)).text());
	}
	catch (e) {
		Config.StoreConfig = {};
		console.log('Error: Could not load configuration data.');
	}
}


// Services/marketplaces
Config.SERVICE_IDS = {
	EBAY: 1,
	AMAZON: 2,
	WOOCOMMERCE: 3,
};

Config.SERVICES = {
	[Config.SERVICE_IDS.EBAY]: {
		id: 'ebay',
		name: 'config.services.ebay',
	},
	[Config.SERVICE_IDS.AMAZON]: {
		id: 'amazon',
		name: 'config.services.amazon',
	},
	[Config.SERVICE_IDS.WOOCOMMERCE]: {
		id: 'woocommerce',
		name: 'config.services.woocommerce',
		checkPayment: false,
	},
};


// Order types
Config.ORDER_TYPE = {
	PARCEL: 1,
	FLATPACK: 2,
	INTERNATIONAL: 3,
	EXPRESS: 4,
};

Config.ORDER_TYPE_INFO = {
	[Config.ORDER_TYPE.PARCEL]: {
		name: 'config.order_type.parcel',
	},
	[Config.ORDER_TYPE.FLATPACK]: {
		name: 'config.order_type.flatpack',
	},
	[Config.ORDER_TYPE.INTERNATIONAL]: {
		name: 'config.order_type.international',
	},
	[Config.ORDER_TYPE.EXPRESS]: {
		name: 'config.order_type.express',
	},
};

// Order type ranks
Config.ORDER_TYPE_RANK = {
	[Config.ORDER_TYPE.EXPRESS]: 3,
	[Config.ORDER_TYPE.PARCEL]: 2,
	[Config.ORDER_TYPE.FLATPACK]: 1,
};


// Order statuses
Config.ORDER_STATUS = {
	NONE: 0,
	COLLECTED: 1,
	PROGRESS: 2,
	PACKED: 3,
	OUTOFSTOCK: 4,
	CANCELLED_OUTOFSTOCK: 5,
	CANCELLED_DISCONTINUED: 6,
	CANCELLED_DONE: 7,
	OVERRIDE: 8,
	LATER: 9,
	READYTOPACK: 10,
	ONHOLD: 11,
};

Config.ORDER_STATUS_NAME = {
	[Config.ORDER_STATUS.NONE]: 'config.order_status.none',
	[Config.ORDER_STATUS.COLLECTED]: 'config.order_status.collected',
	[Config.ORDER_STATUS.PROGRESS]: 'config.order_status.progress',
	[Config.ORDER_STATUS.PACKED]: 'config.order_status.packed',
	[Config.ORDER_STATUS.OUTOFSTOCK]: 'config.order_status.outofstock',
	[Config.ORDER_STATUS.CANCELLED_OUTOFSTOCK]: 'config.order_status.cancelled.outofstock',
	[Config.ORDER_STATUS.CANCELLED_DISCONTINUED]: 'config.order_status.cancelled.discontinued',
	[Config.ORDER_STATUS.CANCELLED_DONE]: 'config.order_status.cancelled.done',
	[Config.ORDER_STATUS.OVERRIDE]: 'config.order_status.override',
	[Config.ORDER_STATUS.LATER]: 'config.order_status.later',
	[Config.ORDER_STATUS.READYTOPACK]: 'config.order_status.readytopack',
	[Config.ORDER_STATUS.ONHOLD]: 'config.order_status.onhold',
};

// Other order data, e.g. categories (IDs from the 'data' field in the order data)
Config.ORDER_DATA_GENERAL = [
	'PostageServiceOptions',
	'backinstock',
];
Config.ORDER_DATA_OTHER = {
	//FASTRACK_FASTWAY_300: 'fastway300',
};


// SKU restrictions
Config.SKU_RESTRICTION = {
	NONE: 0,
	S2: 1, // Pharmacy medicine
	S3: 2, // Prescription medicine
	REFRIGERATED: 3,
	FLAMMABLE: 4,
	S2_REFRIGERATED: 5,
	S2_FLAMMABLE: 6,
	S3_REFRIGERATED: 7,
	S3_FLAMMABLE: 8,
};


// Stock statuses
Config.STOCK_STATUS = {
	INSTOCK: 0,
	OUTOFSTOCK: 1,
	LOWSTOCK: 2,
	ORDERED: 3,
	DISCONTINUED: 4,
	DELAYED: 5,
	DO_NOT_REORDER: 6,
};

Config.STOCK_STATUS_NAME = {
	[Config.STOCK_STATUS.INSTOCK]: 'config.stock_status.instock',
	[Config.STOCK_STATUS.OUTOFSTOCK]: 'config.stock_status.outofstock',
	[Config.STOCK_STATUS.LOWSTOCK]: 'config.stock_status.lowstock',
	[Config.STOCK_STATUS.ORDERED]: 'config.stock_status.ordered',
	[Config.STOCK_STATUS.DISCONTINUED]: 'config.stock_status.discontinued',
	[Config.STOCK_STATUS.DELAYED]: 'config.stock_status.delayed',
	[Config.STOCK_STATUS.DO_NOT_REORDER]: 'config.stock_status.donotreorder',
};


// Postage services
Config.POSTAGE_SERVICE_ID = {
	FASTWAY_A4: 1,
	FASTWAY_A5: 2,
	FASTWAY_METRO: 3,
	AUSPOST_PARCEL_STANDARD: 4,
	AUSPOST_PARCEL_EXPRESS: 5,
	AUSPOST_LETTER_LARGE_250: 6,
	AUSPOST_INTERNATIONAL_STANDARD: 7,
	AUSPOST_INTERNATIONAL_EXPRESS: 8,
	AUSPOST_LETTER_LARGE_125: 9,
	AUSPOST_LETTER_LARGE_500: 10,
	FASTRACK_FASTWAY_300: 11,
	FASTRACK_FASTWAY_1KG: 12,
	DELIVERE_PARCEL: 13,
}

Config.POSTAGE_SERVICES = { // Format: {order type/courier: {postage service: 1, ...}, ...}
	[Config.ORDER_TYPE.PARCEL]: {
		FASTWAY_A4:1, FASTWAY_A5:1, FASTWAY_METRO:1, FASTRACK_FASTWAY_300:1, FASTRACK_FASTWAY_1KG:1,
		AUSPOST_PARCEL_STANDARD:1,
		DELIVERE_PARCEL:1,
	},
	[Config.ORDER_TYPE.FLATPACK]: {AUSPOST_LETTER_LARGE_125:1, AUSPOST_LETTER_LARGE_250:1, AUSPOST_LETTER_LARGE_500:1},
	[Config.ORDER_TYPE.INTERNATIONAL]: {AUSPOST_INTERNATIONAL_STANDARD:1, AUSPOST_INTERNATIONAL_EXPRESS:1},
	[Config.ORDER_TYPE.EXPRESS]: {AUSPOST_PARCEL_EXPRESS:1},
};

// Postage service names
Config.POSTAGE_SERVICE_NAMES = {
	[Config.POSTAGE_SERVICE_ID.FASTWAY_A4]: 'config.postage_services.fastway.a4',
	[Config.POSTAGE_SERVICE_ID.FASTWAY_A5]: 'config.postage_services.fastway.a5',
	[Config.POSTAGE_SERVICE_ID.FASTWAY_METRO]: 'config.postage_services.fastway.metro',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_PARCEL_STANDARD]: 'config.postage_services.auspost.parcel.standard',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_PARCEL_EXPRESS]: 'config.postage_services.auspost.parcel.express',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_LETTER_LARGE_250]: 'config.postage_services.auspost.letter.250g',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_INTERNATIONAL_STANDARD]: 'config.postage_services.auspost.international.standard',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_INTERNATIONAL_EXPRESS]: 'config.postage_services.auspost.international.express',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_LETTER_LARGE_125]: 'config.postage_services.auspost.letter.125g',
	[Config.POSTAGE_SERVICE_ID.AUSPOST_LETTER_LARGE_500]: 'config.postage_services.auspost.letter.500g',
	[Config.POSTAGE_SERVICE_ID.FASTRACK_FASTWAY_300]: 'config.postage_services.fastway.300g',
	[Config.POSTAGE_SERVICE_ID.FASTRACK_FASTWAY_1KG]: 'config.postage_services.fastway.1kg',
	[Config.POSTAGE_SERVICE_ID.DELIVERE_PARCEL]: 'config.postage_services.delivere.parcel',
};


// Postage couriers
Config.POSTAGE_COURIER_IDS = {
	FASTWAY: 1,
	AUSPOST: 2,
	DELIVERE: 3,
},

// Postage courier names (may differ between marketplaces/services)
Config.POSTAGE_COURIER_NAMES = {
	[Config.POSTAGE_COURIER_IDS.FASTWAY]: 'Fastway Couriers',
	[Config.POSTAGE_COURIER_IDS.AUSPOST]: 'Australia Post',
	[Config.POSTAGE_COURIER_IDS.DELIVERE]: 'deliverE',
};

// Postage courier tracking links
Config.POSTAGE_COURIER_TRACKING_LINK_DEFAULT = 'https://t.17track.net/#nums=';
Config.POSTAGE_COURIER_TRACKING_LINKS = {
	[Config.POSTAGE_COURIER_IDS.FASTWAY]: 'https://www.aramex.com.au/tools/track/?l=',
	//[Config.POSTAGE_COURIER_IDS.FASTWAY]: 'https://expect-fastway.azurewebsites.net/Package/Details?trackingNumber=',
	[Config.POSTAGE_COURIER_IDS.AUSPOST]: 'https://auspost.com.au/parcels-mail/track.html#/track?id=',
	[Config.POSTAGE_COURIER_IDS.DELIVERE]: 'https://delivere.com.au/track/',
};

// Services for each postage courier
Config.POSTAGE_COURIER_SERVICES = {
	[Config.POSTAGE_COURIER_IDS.FASTWAY]: {
		FASTWAY_A4:1,
		FASTWAY_A5:1,
		FASTWAY_METRO:1,
		FASTRACK_FASTWAY_300:1,
		FASTRACK_FASTWAY_1KG:1,
	},
	[Config.POSTAGE_COURIER_IDS.AUSPOST]: {
		AUSPOST_PARCEL_STANDARD:1,
		AUSPOST_PARCEL_EXPRESS:1,
		AUSPOST_LETTER_LARGE_250:1,
		AUSPOST_INTERNATIONAL_STANDARD:1,
		AUSPOST_INTERNATIONAL_EXPRESS:1,
		AUSPOST_LETTER_LARGE_125:1,
		AUSPOST_LETTER_LARGE_500:1,
	},
	[Config.POSTAGE_COURIER_IDS.DELIVERE]: {
		DELIVERE_PARCEL:1,
	},
};


// Labels
Config.LABEL_SERVICE = {
	AUSTRALIA_POST_PARCELSEND_DOMESTIC: 3,
	AUSTRALIA_POST_PARCELSEND_INTERNATIONAL: 4,
	AUSTRALIA_POST_EPARCELS_DOMESTIC: 5,
	AUSTRALIA_POST_EPARCELS_INTERNATIONAL: 6,
	FASTWAY: 1,
	EPARCELS: 2,
	DELIVERE: 7,
};

Config.LABEL_SERVICE_INFO = {
	[Config.LABEL_SERVICE.AUSTRALIA_POST_PARCELSEND_DOMESTIC]: {
		name: 'config.label_service.parcelsend.domestic',
		id: 'template-auspost-parcelsend-domestic',
		checkAddress: true,
	},
	[Config.LABEL_SERVICE.AUSTRALIA_POST_PARCELSEND_INTERNATIONAL]: {
		name: 'config.label_service.parcelsend.international',
		//id: 'template-auspost-parcelsend-international',
		visible: false,
	},
	[Config.LABEL_SERVICE.AUSTRALIA_POST_EPARCELS_DOMESTIC]: {
		name: 'config.label_service.auspost_eparcels.domestic',
		id: 'template-auspost-eparcels-domestic',
		checkAddress: true,
		headerBlankRow: true,
	},
	[Config.LABEL_SERVICE.AUSTRALIA_POST_EPARCELS_INTERNATIONAL]: {
		name: 'config.label_service.auspost_eparcels.international',
		id: 'template-auspost-eparcels-international',
		headerBlankRow: true,
	},
	[Config.LABEL_SERVICE.FASTWAY]: {
		name: 'config.label_service.fastway',
		id: 'template-fastway',
		checkAddress: true,
	},
	[Config.LABEL_SERVICE.EPARCELS]: {
		name: 'config.label_service.fasttrack',
		id: 'template-eparcels',
		checkAddress: true,
	},
	[Config.LABEL_SERVICE.DELIVERE]: {
		name: 'config.label_service.delivere',
		id: 'template-delivere',
		checkAddress: true,
	},
};

Config.MANUAL_BARCODE_CHAR_AMOUNT = 4;


// Packaging
Config.PACKAGING_WEIGHT = 45; // Mass of the packaging (grams) e.g. box, bubble wrap
Config.ENVELOPE_WEIGHT = 25; // Mass of an envelope (grams)
Config.PACKAGING_SIZES = {
	ENVELOPE: 1,
	SATCHEL_SMALL: 2,
	SATCHEL_MEDIUM: 3,
	SATCHEL_LARGE: 4,
};

// Packaging names
Config.PACKAGING_NAMES = {
	[Config.PACKAGING_SIZES.ENVELOPE]: 'config.packaging.envelope',
	[Config.PACKAGING_SIZES.SATCHEL_SMALL]: 'config.packaging.satchel.small',
	[Config.PACKAGING_SIZES.SATCHEL_MEDIUM]: 'config.packaging.satchel.medium',
	[Config.PACKAGING_SIZES.SATCHEL_LARGE]: 'config.packaging.satchel.large',
};


// Management
Config.USER_PERMISSION = {
	ADMIN_ACTIONS: 'admin_actions',
	ANALYTICS: 'analytics',
	AWAITING_PACK: 'awaiting_pack',
	BYPASS_SCAN: 'bypass_scan',
	COLLECT: 'collect',
	DOWNLOAD: 'download',
	LABELS: 'labels',
	MANAGE_ORDERS: 'manage_orders',
	PACK: 'pack',
	PACK_STATS: 'pack_stats',
	REFUNDS: 'refunds',
	STOCK: 'stock',
	SILENT: 'silent',
};

Config.USER_PERMISSION_INFO = {
	[Config.USER_PERMISSION.COLLECT]: 'config.user_permission.collect',
	[Config.USER_PERMISSION.PACK]: 'config.user_permission.pack',
	[Config.USER_PERMISSION.ANALYTICS]: 'config.user_permission.analytics',
	[Config.USER_PERMISSION.AWAITING_PACK]: 'config.user_permission.awaiting_pack',
	[Config.USER_PERMISSION.DOWNLOAD]: 'config.user_permission.download',
	[Config.USER_PERMISSION.LABELS]: 'config.user_permission.labels',
	[Config.USER_PERMISSION.MANAGE_ORDERS]: 'config.user_permission.manage_orders',
	[Config.USER_PERMISSION.PACK_STATS]: 'config.user_permission.pack_stats',
	[Config.USER_PERMISSION.REFUNDS]: 'config.user_permission.refunds',
	[Config.USER_PERMISSION.STOCK]: 'config.user_permission.stock',
	[Config.USER_PERMISSION.BYPASS_SCAN]: 'config.user_permission.bypass_scan',
	[Config.USER_PERMISSION.SILENT]: 'config.user_permission.silent',
	[Config.USER_PERMISSION.ADMIN_ACTIONS]: 'config.user_permission.admin_actions',
};


// Pages
Config.PAGE_TYPE = {
	COLLECT: 1,
	STOCK: 2,
	REFUNDS: 3,
	LABELS: 4,
	MANAGE: 5,
	HOME: 6,
	ONHOLD: 7,
	ANALYTICS: 8,
	AWAITING_PACK: 9,
	DOWNLOAD: 10,
	LABEL_TEMPLATES: 11,
	MANAGEMENT: 12,
	PACK: 13,
	STATISTICS: 14,
	STOCK_UPDATE: 15,
	SKU_MANAGE: 16,
};

Config.PAGE_INFO = {
	[Config.PAGE_TYPE.ANALYTICS]: {
		name: 'analytics',
		title: 'config.page_type.analytics.title',
		heading: '',
		icon: '📊',
		position: '',
	},
	[Config.PAGE_TYPE.AWAITING_PACK]: {
		name: 'awaitpack',
		title: 'config.page_type.awaitpack.title',
		heading: '',
		icon: '🗃️',
		position: 'left',
	},
	[Config.PAGE_TYPE.COLLECT]: {
		name: 'collect',
		title: 'config.page_type.collect.title',
		heading: 'config.page_type.collect.heading',
		icon: '🗃',
		position: 'left',
		action: 'config.page_type.collect.action',
	},
	[Config.PAGE_TYPE.DOWNLOAD]: {
		name: 'download',
		title: 'config.page_type.download.title',
		heading: '',
		icon: '📲',
		position: '',
	},
	[Config.PAGE_TYPE.HOME]: {
		name: 'home',
		title: 'config.page_type.home.title',
		heading: 'config.page_type.home.heading',
		icon: '',
		position: '',
	},
	[Config.PAGE_TYPE.LABEL_TEMPLATES]: {
		name: 'label_templates',
		title: 'config.page_type.label_templates.title',
		heading: '',
		icon: '📃',
		position: '',
	},
	[Config.PAGE_TYPE.LABELS]: {
		name: 'labels',
		title: 'config.page_type.labels.title',
		heading: '',
		icon: '🖨',
		position: 'left',
		action: 'config.page_type.labels.action',
	},
	[Config.PAGE_TYPE.MANAGE]: {
		name: 'manage',
		title: 'config.page_type.manage.title',
		heading: '',
		icon: '📊',
		position: '',
	},
	[Config.PAGE_TYPE.MANAGEMENT]: {
		name: 'management',
		title: 'config.page_type.management.title',
		heading: '',
		icon: '⚙️',
		position: '',
	},
	[Config.PAGE_TYPE.ONHOLD]: {
		name: 'onhold',
		title: 'config.page_type.onhold.title',
		heading: '',
		icon: '⏸️',
		position: 'left',
		action: 'config.page_type.onhold.action',
	},
	[Config.PAGE_TYPE.PACK]: {
		name: 'pack',
		title: 'config.page_type.pack.title',
		heading: '',
		icon: '📦',
		position: '',
	},
	[Config.PAGE_TYPE.REFUNDS]: {
		name: 'refunds',
		title: 'config.page_type.refunds.title',
		heading: '',
		icon: '📩',
		position: 'left',
		action: 'config.page_type.refunds.action',
	},
	[Config.PAGE_TYPE.SKU_MANAGE]: {
		name: 'sku_manage',
		title: 'config.page_type.sku_manage.title',
		heading: '',
		icon: '📸',
		position: '',
	},
	[Config.PAGE_TYPE.STATISTICS]: {
		name: 'statistics',
		title: 'config.page_type.statistics.title',
		heading: '',
		icon: '📊',
		position: '',
	},
	[Config.PAGE_TYPE.STOCK]: {
		name: 'stock',
		title: 'config.page_type.stock.title',
		heading: '',
		icon: '🧾',
		position: '',
	},
	[Config.PAGE_TYPE.STOCK_UPDATE]: {
		name: 'stockupdate',
		title: 'config.page_type.stockupdate.title',
		heading: '',
		icon: '📤',
		position: '',
	},
};


// Page tabs - ordered by their numbers
Config.PAGE_TAB = {
	PARCELS: 1,
	FLATPACK: 2,
	EXPRESS: 3,
	INTERNATIONAL: 4,
};

Config.PAGE_TAB_INFO = {
	[Config.PAGE_TAB.PARCELS]: {
		name: 'config.page_tab.parcels', id: 'tab-parcels', href: '#parcels',
		orderTypes: [Config.ORDER_TYPE.PARCEL],
	},
	[Config.PAGE_TAB.FLATPACK]: {
		name: 'config.page_tab.flatpack', id: 'tab-flatpack', href: '#flatpack',
		orderTypes: [Config.ORDER_TYPE.FLATPACK],
	},
	[Config.PAGE_TAB.EXPRESS]: {
		name: 'config.page_tab.express', id: 'tab-express', href: '#express',
		orderTypes: [Config.ORDER_TYPE.EXPRESS],
	},
	[Config.PAGE_TAB.INTERNATIONAL]: {
		name: 'config.page_tab.international', id: 'tab-international', href: '#international',
		orderTypes: [Config.ORDER_TYPE.INTERNATIONAL],
	},
};


// Order category toggle - ordered by their numbers
/*Config.PAGE_ORDER_CATEGORY = {
	//FASTRACK_FASTWAY_300: 1,
};

Config.PAGE_ORDER_CATEGORY_INFO = {
	[Config.PAGE_ORDER_CATEGORY.FASTRACK_FASTWAY_300]: {
		name: 'config.page_category.fastway300',
		orderCategories: [Config.ORDER_DATA_OTHER.FASTRACK_FASTWAY_300],
		orderCategoriesExcluded: [Config.ORDER_DATA_OTHER.SOMETHING],
	},
};*/


// Order urgency and time in seconds
Config.ORDER_URGENCY = {
	MEDIUM: 345600, // 4 days
	HIGH: 691200, // 8 days
	VERYHIGH: 2592000, // 30 days
	EXTREME: 5184000, // 60 days
};

// Product placeholder image
Config.PRODUCT_PLACEHOLDER_IMAGE = '/common/img/placeholder.jpg';

// Done screen
Config.showDoneScreenImages = true;
Config.doneScreenImages = [
	// [src, alt] or [weight, [src, alt]]
	['img/fireworks.gif', 'Fireworks'],
	['img/ryan/cheer-up/emot_003_x3.gif', 'Dance shoes'],
	['img/ryan/cheer-up/emot_004_x3.gif', 'Flags'],
	['img/ryan/cheer-up/emot_006_x3.gif', 'Dance'],
	['img/ryan/cheer-up/emot_008_x3.gif', 'Music'],
	['img/ryan/special/emot_005_x3.gif', 'Jump'],
	['img/ryan/special/emot_008_x3.gif', 'Dance'],
	['img/ryan/special/emot_010_x3.gif', 'Jump to the stars'],
	['img/ryan/special/emot_015_x3.gif', 'Thumbs up'],
	['img/ryan/special/emot_020_x3.gif', 'Salute'],
	['img/ryan/special/emot_024_x3.gif', 'Praise'],
	['img/ryan/003.gif', 'TV'],
	['img/ryan/006.gif', 'Glow sticks'],
	['img/ryan/017.gif', 'Proper'],
	['img/ryan/022.gif', 'Yes'],
	['img/ryan/dance.gif', 'Dance'],
	['img/ryan/delivery.gif', 'Delivery'],
	['img/muji/emot_004_x3.gif', 'Okay'],
	['img/muji/emot_009_x3.gif', 'Thumbs up'],
	['img/muji/emot_010_x3.gif', '??'],
	['img/muji/emot_017_x3.gif', 'Dance party'],
	['img/muji/emot_021_x3.gif', 'Disco'],
	['img/muji/emot_023_x3.gif', 'Sleeping'],
	['img/jay-cool.gif', 'Cool'],
	['img/theme_frodo_neo_tube.gif', 'Confetti'],
];

// Actions
Config.LIVE_ACTIONS = {
	ORDER: {
		STATUS_CHANGED: 1,
		ORDER_OPENED: 2,
		ORDER_CLOSED: 3,
		ORDER_ALL: 4,
		GROUP_CHANGED: 5,
	},
	STOCK: {
		INSTOCK: 5,
		OUTOFSTOCK: 6,
		LOWSTOCK: 7,
	},
	NOTIFICATION: 8,
};

Config.SCAN_ACTIONS = {
	DONE: 1,
	WAITING: 2,
	WAITING_FLASH: 3,
	EXCEEDED: 4,
};


Config.getOrderType = (postageService) => {
	let orderType = null;
	if (postageService === null || postageService === undefined) return null;

	postageServiceLoop:
	for (let type in Config.POSTAGE_SERVICES) {
		for (let service in Config.POSTAGE_SERVICES[type]) {
			if (Config.POSTAGE_SERVICE_ID[service] == postageService) {
				orderType = Number(type);
				break postageServiceLoop;
			}
		}
	}
	return orderType;
}

export default Config;

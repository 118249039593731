export default {
	// Configuration
	"blank": "Blank",
	"na": "N/A",
	"none": "None",
	"notification": "Notification",
	"config": {
		"label_service": {
			"delivere": "deliverE",
			"fastway": "Fastway Couriers",
			"fasttrack": "FastTrack eParcels",
			"auspost_eparcels": {
				"domestic": "AP eParcels Domestic",
				"international": "AP eParcels International",
			},
			"parcelsend": {
				"domestic": "AP Parcel Send Domestic",
				"international": "AP Parcel Send International",
			},
		},
		"order_status": {
			"none": "New order",
			"collected": "Collected",
			"progress": "In progress",
			"packed": "Packed",
			"outofstock": "Out of stock",
			"cancelled": {
				"outofstock": "Cancellation - Out of stock",
				"discontinued": "Cancellation - Discontinued",
				"done": "Cancellation done",
			},
			"override": "Override",
			"later": "Pack later",
			"readytopack": "Ready to pack",
			"onhold": "On hold",
		},
		"order_type": {
			"express": "Express",
			"flatpack": "Flat-pack",
			"international": "International",
			"parcel": "Parcel",
		},
		"packaging": {
			"none": "None",
			"envelope": "Envelope",
			"satchel": {
				"small": "Small satchel",
				"medium": "Medium satchel",
				"large": "Large satchel",
			},
		},
		"page_category": {
			"burwood": "Burwood orders",
			"fastway300": "Fastway/flat-pack 300g",
		},
		"page_tab": {
			"parcels": "Parcels",
			"flatpack": "Flat-packed",
			"express": "Express Post",
			"international": "International",
		},
		"page_type": {
			"analytics": {
				"title": "Pricing & Analytics",
			},
			"awaitpack": {
				"title": "Awaiting Packing",
			},
			"collect": {
				"title": "Orders",
				"heading": "New Orders",
				"action": "collect",
			},
			"download": {
				"title": "Download Orders",
			},
			"home": {
				"title": "Home",
				"heading": "Welcome to {{STORE_NAME}}",
			},
			"label_templates": {
				"title": "Label Templates",
			},
			"labels": {
				"title": "Create Labels",
				"action": "do",
			},
			"manage": {
				"title": "Manage Orders",
			},
			"management": {
				"title": "Management",
			},
			"onhold": {
				"title": "Orders On Hold",
				"action": "do",
			},
			"pack": {
				"title": "Packing",
			},
			"refunds": {
				"title": "Refund Orders",
				"action": "refund",
			},
			"sku_manage": {
				"title": "SKU Management",
			},
			"statistics": {
				"title": "Packing Statistics",
			},
			"stock": {
				"title": "Stock Orders",
			},
			"stockupdate": {
				"title": "Stock Update",
			},
		},
		"postage_services": {
			"fastway": {
				"a4": "Fastway A4",
				"a5": "Fastway A5",
				"metro": "Fastway Metro",
				"300g": "Fastway 300g",
				"1kg": "Fastway 1kg",
			},
			"auspost": {
				"international": {
					"standard": "Australia Post International Parcel - Standard",
					"express": "Australia Post International Parcel - Express",
				},
				"parcel": {
					"standard": "Australia Post Domestic Parcel - Standard",
					"express": "Australia Post Domestic Parcel - Express",
				},
				"letter": {
					"125g": "Australia Post Letter 125g",
					"250g": "Australia Post Letter 250g",
					"500g": "Australia Post Letter 500g",
				},
			},
			"delivere": {
				"parcel": "deliverE Parcel",
			},
		},
		"services": {
			"ebay": "eBay",
			"amazon": "Amazon",
			"woocommerce": "WooCommerce",
		},
		"stock_status": {
			"instock": "In stock",
			"outofstock": "Out of stock",
			"lowstock": "Low stock",
			"ordered": "Ordered",
			"discontinued": "Discontinued",
			"delayed": "Delayed",
			"donotreorder": "Do not reorder",
		},
		"user_permission": {
			"admin_actions": "Admin actions",
			"analytics": "Analytics",
			"awaiting_pack": "Awaiting packing",
			"bypass_scan": "Bypass scanning",
			"collect": "Collect orders",
			"download": "Download orders",
			"labels": "Create labels",
			"manage_orders": "Manage orders",
			"management": "Management",
			"pack": "Pack orders",
			"pack_stats": "Pack stats",
			"refunds": "Refunds",
			"silent": "Silent user",
			"stock": "Stock",
		},
	},

	// Analytics
	"analytics": {
		"buttons": {
			"show": "Show",
			"export_csv": "Export as CSV",
			"import_streetpricer": "Import StreetPricer CSV",
		},
		"menu": {
			"choose_store": "Choose store:",
			"product": "Product analytics",
			"streetpricer": "StreetPricer",
			"unpopular_products": "Unpopular SKUs",
		},
		"notifications": {
			"cannot_get_skus": "Error: Could not get SKU data.",
			"import_successful": "The StreetPricer data has successfully been imported.",
			"select_download_store": "Please select the store to import data for.",
			"select_upload_file": "Please choose a CSV file to be imported.",
		},
		"products": {
			"date_range": {
				"all": "All time",
				"day": "Last day",
				"month": "Last month",
				"six_months": "Last 6 months",
				"three_days": "Last 3 days",
				"three_months": "Last quarter",
				"title": "Date range for orders:",
				"week": "Last week",
				"year": "Last year",
			},
			"filter": {
				"quantity": "Filter sales quantity:",
				"quantity_placeholder": "Enter quantity",
				"skus": "Filter SKUs:",
				"skus_placeholder": "Enter SKU",
			},
			"summary": {
				"orders": "Total Orders",
				"profit": "Total Profit",
				"revenue": "Total Revenue",
				"skus": "Total SKUs",
			},
			"table": {
				"cost_price": "Current Cost Price",
				"id": "SKU",
				"mpn": "MPN",
				"order_cost_price": "Cost Price",
				"order_id": "Order ID",
				"order_profit": "Profit per unit",
				"order_quantity": "Quantity",
				"order_sale_date": "Date",
				"order_sale_price": "Sale Price",
				"order_total_profit": "Total profit",
				"profit": "Profit",
				"record_id": "Record ID",
				"sale_price": "Last Sale Price",
				"sales_quantity": "Sales Quantity",
				"sales_revenue": "Sales Revenue",
				"sale_date": "Last Sale Date",
				"show_orders": "Show orders",
				"store": "Store",
			},
			"no_skus": "No SKUs with orders in the specified date range",
		},
	},

	// Awaiting Packing
	"awaiting_pack": {
		"logout": "Return to Menu",
		"loading_orders": "Loading orders...",
		"no_orders": "No orders",
		"days": "day(s)",
		"tab": {
			"all": "All",
			"express": "Express",
			"flatpack": "Flatpack",
			"international": "International",
			"no_tracking": "No Tracking",
			"pack_later": "Pack Later",
			"parcel": "Parcel",
		},
		"button": {
			"open_tracking": "Open Tracking",
			"prev_order": "Previous Order",
			"next_order": "Next Order",
			"await_label": "Awaiting Orders",
			"done_label": "Completed Orders",
		},
		"notifications": {
			"no_li": "No order selected.",
			"no_tracking": "No tracking found for this order.",
			"load_order_error": "Could not load order data.",
			"load_wait": "Loading... Please wait.",
		},
	},

	// Collecting
	"collect": {
		"categories": "Categories",
		"nav": {
			"home": "Home",
			"logout": "Logout",
			"menu": "Menu",
			"refresh": "Refresh",
			"username": "Someone",
		},
		"order": {
			"buttons": {
				"add_for_collection": "Add for collection",
				"add_notes": "Add collecting notes",
				"add_notes_packing": "Add packing notes",
				"collect": {
					"bypass": "Bypass scan",
					"lowstock": "Low stock",
					"order": "Collect order",
					"outofstock": "Out of stock",
					"parcel": "Collect as parcel",
					"reset": "Reset order",
					//"warehouse": "Warehouse order",
				},
				"delivere": "Generate deliverE",
				"discontinued": "Discontinued",
				"donotreorder": "Do not reorder",
				"fasttrack": "Generate FastTrack",
				"fastway": "Generate Fastway",
				"fastway300": "Generate Fastway 300g",
				"fastway1kg": "Generate Fastway 1kg",
				"flatpack125": "Generate Flat-pack 125g",
				"flatpack250": "Generate Flat-pack 250g",
				"eparcels": "Generate eParcels",
				"express": "Generate Parcel Send Express",
				"express_eparcels": "Generate eParcels Express",
				"international": "Generate eParcels International",
				"labels": {
					"changeservice": "Save changes",
					"choose_labels": "Choose the type of labels to create",
					"copyorder": "Copy order details",
					"service": "Postage service",
				},
				"onhold": "Place order on hold",
				"ordered": "Ordered",
				"outofstock": "Out of stock order",
				"parcelsend": "Generate Parcel Send",
				"refunds": {
					"cancel": "Mark as cancelled",
					"ebay": "Copy eBay order link",
					"ebaymessage": "Copy eBay message/cancel links",
					"woocommerce": "Open WooCommerce order link",
				},
				"stock": {
					"cancel": "Cancel order",
					"instock": "Back in stock",
					"notordered": "Mark as not ordered",
					"ordered": "Mark as ordered",
				},
			},
			"buyer_message": "Message from buyer",
			"combined": "Combined order",
			"done": {
				"start": "There's nothing left to",
				"end": "here",
			},
			"group": "Bucket",
			"invoice": "Transfer Request",
			"list": "Loading orders...",
			"modified": "Modified",
			"next": "Next order",
			"notes": "Collecting notes",
			"notes_packing": "Packing notes",
			"post": {
				"flatpacked": "Flat-packed",
				"auspost": "Australia Post",
				"fastway": "Fastway Couriers",
				"express": "Express",
				"international": "International sale",
			},
			"previous": "Previous order",
			"products": {
				"expiry": "Expiry",
				"image": "Image",
				"name": "Product Name",
				"price": "Price",
				"quantity": "Quantity",
				"scanned": "Scanned",
				"sku": "SKU / Barcode",
				"subtotal": "Subtotal",
				"variation": "Variation",
			},
			"inventory": {
				"expiry": "Expiry",
				"location": "Location",
				"lot": "Lot",
				"quantity": "Quantity",
			},
			"scan": {
				"cannot_be_scanned": "Cannot be scanned",
				"done": "All products scanned ✓",
				"waiting": "Please scan products",
				"warning": "Excess quantity scanned",
			},
			"search": "Search for an order",
			"title": {
				"account_id": "Account ID",
				"country": "Country",
				"date": "Date",
				"id": "ID",
				"more": "More",
				"name": "Name",
				"orderid": "Order ID",
				"postage_service_system": "System Postage Service",
				"recordnum": "Record #",
				"status": "Status",
				"store": "Store",
				"userid": "User ID",
			},
			"total_orders": "Total orders",
		},
		"labels": {
			"batch_count": "Orders in batch",
			"batch_saved": "Batch saved",
			"buttons": {
				"clear_batch": "Clear batch",
				"save_batch": "Save orders as batch",
				"show_all_orders": "Show all orders",
				"show_batch": "Show saved batch",
			},
			"total_count": "Total orders",
		},
		"box": {
			"barcode": {
				"no_matches": "The barcode scanned does not match any of the products in this order.",
				"not_available": "Barcode scanning using the camera is not available on this device.",
				"title": "Barcode scanner",
			},
			"batch": {
				"save": {
					"save": "Save orders as batch",
					"text": "Are you sure you want to save all the current orders as a batch?<br>This will overwrite any existing saved batch.",
					"title": "Confirm save batch",
				},
				"clear": {
					"clear": "Clear saved batch",
					"text": "Are you sure you want to clear the saved batch?",
					"title": "Confirm clear batch",
				},
			},
			"cancel": {
				"cancel": "Cancel order",
				"discontinued": "Discontinued",
				"notes": "Enter any additional order information here, e.g. for partial orders",
				"outofstock": "Out of stock",
				"partial": "Partial order",
				"text": "Reason for cancellation",
				"title": "Cancel Order",
			},
			"combined": {
				"title": "Instructions for combined orders",
				"text": "For combined orders, please click the same button for each order if they are to be processed together.",
			},
			"onhold": {
				"confirm": "Confirm",
				"text": "Are you sure you want to place this order on hold?",
			},
			"outofstock": {
				"confirm": "Save and mark order as out of stock",
				"text": "Enter the number of products that are in stock<br>and are to be reserved for this order.",
			},
			"notes": {
				"orderid": "Order #",
				"save": "Save notes",
				"store": "Store",
				"text": "Enter any notes for this order here",
				"title": "Add collecting notes to this order",
				"title_packing": "Add packing notes to this order",
			},
		},
		"notifications": {
			"batch_loaded": "Saved batch has been loaded.",
			"batch_saved": "Batch saved.",
			"batch_cleared": "Batch cleared.",
			"batch_no_orders": "There are no orders right now.",
			"bucket_number_out_of_range": "This order's bucket number is outside the range of available buckets and can't be shown.",
			"cannot_generate_labels": "Error: Labels cannot be generated for this order type/postage service.",
			"cannot_load_item": "Error: item {{ITEM_ID}} could not be loaded from the database.",
			"cannot_load_item_details": "Error: Could not get product details from the server.",
			"cannot_load_orders": "Error: Could not load orders from the server.",
			"cannot_save_batch": "Error: Could not save batch in the server.",
			"cannot_set_item_location": "Error: Could not set item location in the server.",
			"cannot_set_item_status": "Error: Could not set item stock status in the server.",
			"cannot_update_order": "Error: Could not update order on the server.",
			"cannot_update_product_notes": "Error: Could not update product notes in the server.",
			"courier_postage_service_mismatch": "Error: Courier service and postage service do not match for one or more buttons.",
			"invalid_label_service": "Error: Invalid label service specified.",
			"invalid_postage_service": "Error: Invalid postage service specified.",
			"item_does_not_have_sku": "Error: Product does not have a SKU.",
			"item_location_updated": "The location for item {{ITEM_ID}} has been updated.",
			"item_sku_marked": "Product SKU {{SKU}} has been marked as '{{STOCK_STATUS_NAME}}'.",
			"item_status_updated_recently": "Product status was recently updated by another person and cannot be changed.",
			"load_brands": "Error: Could not load the brand list.",
			"no_matching_courier": "Error: Could not find a matching courier for the selected postage service.",
			"no_orders_to_pack": "There are no orders of this type to be packed right now.",
			"no_saved_batch": "There is currently no saved batch.",
			"notes_saved": "Notes saved for item {{ITEM_ID}}.",
			"order_data_unavailable": "Order data is currently not available. Please try again after a few seconds.",
			"order_updated": "Order {{ORDER_ID}} has been updated.",
			"orders_missing_postage_service": "Error: One or more orders is does not have a postage service set.",
			"other_reason_needed": "Please enter a reason for 'other' in the text box.",
			"out_of_stock_product_needed": "At least one product in this order needs to be set as out of stock before marking the order as out of stock.",
			"postage_service_not_determined": "Error: A postage service could not be determined for this order.",
			"unknown_action": "Error: Unknown action.",
		},
	},

	// Download orders
	"download": {
		"buttons": {
			"cancel": "Cancel",
			"refresh": "Refresh",
			"reset": "Reset",
			"save_changes": "Save changes",
			"show": "Show",
			"add_collection": "Add orders for collection",
			"remove_selected": "Remove selected orders",
			"delete_selected": "Delete selected orders",
			"marksent": "Mark orders as sent",
			"marksent_selected": "Mark selected orders as sent",
			"select_tracking": "Select all orders with tracking",
			"upload_tracking": "Upload tracking numbers",
			"upload_tracking_selected": "Upload tracking for selected orders",
			"mark_tracking_uploaded": "Mark tracking as uploaded",
			"generate_tracking": "Generate missing tracking numbers",
		},
		"menu": {
			"choose_store": "Choose store:",
			"ebay": "eBay orders",
			"amazon": "Amazon orders",
			"woocommerce": "WooCommerce orders",
			"neworders": "New orders",
			"marksent": "Mark orders as sent",
			"tracking": "Upload tracking details",
		},
		"download": {
			"download": "Download orders",
			"downloading": "Downloading orders, please wait...",
		},
		"orders": {
			"confirm_add": "Are you sure you want to add the selected orders for collection?",
			"confirm_remove": "Are you sure you want to remove the selected orders?",
			"filter": {
				"title": "Filter:",
				"all": "All",
				"messages": "Messages",
				"paid": "Paid orders",
				"notpaid": "Not paid",
				"express": "Express",
				"international": "International",
			},
			"table": {
				"id": "ID",
				"stores": "Store",
				"recordid": "Record ID",
				"buyerid": "Buyer ID",
				"price": "Price",
				"firstname": "First Name",
				"lastname": "Last Name",
				"delivery_name": "Name for Delivery",
				"address1": "Address 1",
				"address2": "Address 2",
				"city": "City",
				"state": "State",
				"postcode": "Postcode",
				"country": "Country",
				"phone": "Phone",
				"delivery_note": "Delivery Note",
				"tracking": "Tracking number",
			},
			"notice": {
				"delivery_instructions": "For orders with messages that contain delivery instructions, save the instructions into the \"Delivery Note\" field.",
				"pending_payment": "Orange rows indicate that the payments for those orders may be pending (e.g. bank transfers) - check that the payments have been cleared through eBay/PayPal/bank <strong>before adding them for collection</strong>.",
				"unpaid": "Red rows indicate orders that had not been paid for at the time of the download - check that these have been paid for on eBay/PayPal/bank <strong>before adding them for collection</strong>.",
				"save_changes": "Save any changes you have made before adding the orders for collection.",
			},
			"no_orders": "No orders",
			"message": "Message:",
			"adding_collection": "Adding orders for collection...",
		},
		"marksent": {
			"confirm_marksent": "Are you sure you want to mark the selected orders as sent?",
			"filter": {
				"title": "Filter orders:",
				"all": "All",
				"collected": "Collected",
				"readytopack": "Ready to pack",
				"packed": "Packed",
				"override": "Overridden",
			},
			"notice": {
				"pending_payment": "Orange rows indicate that the payments for those orders may be pending (e.g. bank transfers) - check that the payments have been cleared through eBay/PayPal/bank <strong>before marking these orders as sent</strong>.",
				"unpaid": "Red rows indicate orders that had not been paid for at the time of the download - check that these have been paid for on eBay/PayPal/bank <strong>before marking these orders as sent</strong>.",
			},
			"marking_sent": "Marking orders as sent, please wait...",
		},
		"tracking": {
			"confirm_upload": "Are you sure you want to upload tracking numbers<br>for the selected orders?",
			"confirm_mark_upload": "Are you sure you want to mark the selected orders<br>as having tracking numbers uploaded?",
			"confirm_delete": "Are you sure you want to delete the selected orders?",
			"filter_title": "Filter:",
			"generated": "Tracking numbers have been generated for the selected orders.",
			"notice": {
				"save_changes": "Save any changes you have made before uploading the tracking numbers.",
				"clear": "Periodically clear entries that have been sent and don't have tracking numbers (mark as uploaded).",
				"ebay_mark_sent": "eBay orders will also be marked as sent when the tracking numbers are uploaded (if not already).",
				"outofstock": "Red rows indicate orders that are out of stock.",
			},
			"marking_uploaded": "Marking tracking for orders as uploaded...",
			"uploading": "Uploading tracking details, please wait...",
		},
		"notifications": {
			"select_download_store": "Please select a store to download from.",
			"cannot_download_orders": "Error: Could not download the latest orders.",
			"orders_downloaded": "The latest orders have successfully been downloaded.",
			"no_orders_changed": "No orders have been changed.",
			"order_changes_saved": "Changes to the orders have been saved into the database.",
			"no_orders_selected": "No orders selected.",
			"orders_added_collection": "Orders have been added for collection.",
			"cannot_add_orders_collection": "Error: Could not add orders for collection.",
			"cannot_set_orders_cancelled": "Error: The selected orders could not be set to cancelled in the database.",
			"orders_set_cancelled": "The selected orders have been set to cancelled in the database.",
			"orders_marked_sent": "Orders have been marked as sent",
			"only_in_database": "only in the database",
			"cannot_mark_sent": "Error: Could not mark orders as sent.",
			"select_store": "Please select a store first.",
			"tracking_changes_saved": "Changes to the tracking numbers have been saved into the database.",
			"tracking_uploaded": "Tracking details for the selected orders have been uploaded.",
			"tracking_uploaded_db": "Tracking details for the selected orders have been marked as uploaded in the database.",
			"cannot_upload_tracking": "Error: Could not upload tracking details and/or update the database.",
			"tracking_exists": "Error: One or more of the selected orders already have tracking numbers.",
		},
	},

	// Home
	"home": {
		"title": "Home",
		"username": "Enter your username",
		"password": "Enter your password",
		"logged_in": "Logged in as",
		"buttons": {
			"analytics": "Pricing & Analytics",
			"collect": "Collect Orders",
			"download": "Download Orders",
			"label_templates": "Label Templates",
			"labels": "Create Labels",
			"login": "Login",
			"manage": "Manage Orders",
			"management": "Management",
			"onhold": "Orders On Hold",
			"pack": "Pack Orders",
			"packing_list": "Awaiting Packing List",
			"refunds": "Refunds",
			"sku_manage": "SKU Management",
			"statistics": "Packing Statistics",
			"stock": "Stock Orders",
			"stock_update": "Stock Update",
		},
		"notifications": {
			"invalid_page_type": "Error: Invalid page type",
			"enter_username_password": "Please enter your username and password.",
			"cannot_connect_server": "Error: Could not connect to the server.",
			"cannot_access_page": "Error: You are not logged in or your account is not able to access this page.",
		},
	},

	// Label template
	"labels": {
		"title": "Label Templates 📃",
		"main": "Welcome to the label template generator",
		"add": "Add",
		"cancel": "Cancel",
		"confirm_remove": "Are you sure you want to remove the selected orders?",
		"create_tracking": {
			"button": "Create tracking IDs",
			"title": "Create tracking IDs via API",
			"text": "Tracking IDs will be created via API for the selected orders that have been added from the labels page or using an order ID.",
			"confirm": "Confirm",
		},
		"duplicate": "Duplicate selected row",
		"fasttrack": {
			"weight": "Weight (kg)",
			"quantity": "Qty",
			"items": "Items",
			"name": "Name",
			"company": "Company",
			"phone": "Phone",
			"email": "Email",
			"address1": "Address1",
			"address2": "Add2",
			"address3": "Add3",
			"city": "City",
			"state": "State",
			"postcode": "Postcode",
			"description": "Description",
			"delivery_note": "Delivery note",
			"reference": "Reference",
			"email_notification": "Email notification",
			"sender": {
				"name": "Sender name",
				"company": "Sender company",
				"phone": "Sender phone",
				"email": "Sender email",
				"address1": "Sender address1",
				"address2": "Sender address2",
				"address3": "Sender address3",
				"city": "Sender city",
				"state": "Sender state",
				"postcode": "Sender postcode",
			},
		},
		"fastway": {
			"conote": "Conote no",
			"multibusiness": "Multibusiness",
			"accountno": "Account no",
			"name": "Name",
			"company": "Company",
			"address1": "Add1",
			"address2": "Add2",
			"city": "Suburb",
			"state": "City/State",
			"postcode": "Postcode",
			"email": "Email",
			"phone": "Phone",
			"mobile": "Mobile",
			"delivery_note": "Delivery note",
			"special2": "Special2",
			"special3": "Special3",
			"items": "Items",
			"reference": "Reference",
			"packaging": "Packaging",
			"weight": "Weight",
			"width": "Width",
			"length": "Length",
			"count": "Count",
		},
		"generate": {
			"labels": "Label PDF",
			"labels_multiple": "Address label PDF",
			"store_labels": "Store PDF",
		},
		"getorder": {
			"title": "Add order",
			"input": "Enter order ID",
			"getorder": "Get order",
			"blankrow": "Add blank row",
			"storerow": "Add store row",
		},
		"menu_main": "Main",
		"notice": {
			"address": "Orange rows indicate possible errors with the suburb/state/postcode.",
			"foreign": "If any fields contain non-English characters, translate them into English and also print a copy of their address in the original language, to be affixed to the parcel.",
		},
		"notifications": {
			"buyer_details_invalid_chars": "The buyer details at the highlighted fields/rows contain invalid characters.",
			"buyer_name_too_long": "The buyer name(s) at the highlighted row(s) cannot be longer than {{BUYER_NAME_MAX_LENGTH}} characters.",
			"cannot_create_tracking_data": "Error: Could not create tracking IDs for the selected orders.",
			"cannot_mark_ready_to_pack": "Error: Could not mark orders as ready to pack.",
			"cannot_tracking_data": "Error: Could not save tracking data.",
			"city_state_postcode_missing": "Error: City/state/postcode columns are missing.",
			"columns_cannot_be_blank": "The following columns at the highlighted row(s) cannot be blank",
			"enter_order_number": "Please enter an order number.",
			"invalid_tracking_data": "Error: Invalid tracking data.",
			"marked_ready_to_pack": "The selected orders have been marked as ready to pack.",
			"no_orders_added": "No order have been added.",
			"no_orders_selected": "No orders selected.",
			"no_orders_selected_ready": "No orders selected or the selected orders cannot be marked as ready to pack.",
			"no_orders_selected_tracking": "No orders selected or tracking IDs cannot be created for the selected orders.",
			"only_one_duplicate": "Only one order can be duplicated at a time.",
			"order_number_does_not_exist": "Order number does not exist.",
			"order_added": "Order {{ORDER_ID}} has been added.",
			"order_does_not_exist": "Order number does not exist.",
			"postage_prices_missing": "Postage price data is missing in the configuration.",
			"suburb_postcode_errors": "Possible suburb/postcode errors at the highlighted row(s)",
			"tracking_data_created": "Tracking IDs have been created for the selected orders.",
			"tracking_data_saved": "Tracking data has been saved.",
			"unknown_document_type": "Unknown document type.",
			"unknown_postage_type": "Error: Unknown postage type.",
		},
		"parcelsend": {
			"rowtype": "Row type",
			"sender_account": "Sender account",
			"payer_account": "Payer account",
			"charge_code": "Charge Code",
			"name": "Name",
			"company": "Company",
			"address1": "Address1",
			"address2": "Add2",
			"address3": "Add3",
			"address4": "Add4",
			"suburb": "Suburb",
			"state": "State",
			"postcode": "Postcode",
			"country": "Country",
			"signature_required": "Signature required",
			"tracking_email": "Send tracking email",
			"email": "Email",
			"phone": "Phone",
			"delivery_note": "Delivery Note",
			"special2": "Special 2",
			"special3": "Special 3",
			"reference": "Reference",
			"reference2": "Reference 2",
			"importer_reference": "Importer Reference",
			"productid": "Product ID",
			"authority_to_leave": "Authority to leave",
			"safedrop": "Safe drop",
			"quantity": "Quantity",
			"packaging": "Packaging type",
			"declared_value": "Declared value",
			"weight": "Weight",
			"length": "Length",
			"width": "Width",
			"height": "Height",
			"contents": "Parcel contents",
			"transit_cover": "Transit cover value",
			"chargeback_account": "Chargeback Account",
			"classification": "Classification",
			"countryoforigin": "Country of origin",
			"description": "Description",
			"unit_weight": "Unit weight",
			"unit_value": "Unit value",
			"total_value": "Total value",
		},
		"pdf": {
			"bill_to": "Bill To",
			"company": "Company",
			"date": "Date",
			"delivery":" Delivery",
			"discount": "Discount",
			"from": "From",
			"including": "incl.",
			"invoice_num": "Invoice no.",
			"of": "of",
			"order_num": "Order #",
			"page": "Page",
			"post_to": "Post to",
			"price": "Price",
			"product_name": "Product Name",
			"quantity": "Quantity",
			"reference_num": "Reference #",
			"sku": "SKU",
			"subtotal": "Subtotal",
			"tax_invoice": "Tax Invoice",
			"total": "Total",
			"unit_price": "Unit Price",
		},
		"ready": {
			"title": "Confirm ready to pack",
			"text": "The selected orders that have been added from the labels page or using an order ID will be marked as ready to be packed.",
			"confirm": "Confirm",
			"cancel": "Cancel",
		},
		"ready_to_pack": "Mark as ready to pack",
		"tracking": {
			"title": "Tracking data",
			"text": "Enter tracking data here",
			"save": "Save tracking data",
			"cancel": "Cancel",
		},
		"remove": "Remove selected orders",
		"save_document": "Save document for upload",
		"save_tracking": "Add tracking IDs",
	},

	// Manage orders
	"manage": {
		"buyer": {
			"address1": "Address 1",
			"address2": "Address 2",
			"city": "City",
			"company": "Company",
			"country": "Country",
			"deliverynote": "Delivery Note",
			"name": "Name for Delivery",
			"phone": "Phone",
			"postcode": "Postcode",
			"state": "State",
			"title": "Buyer Details (editable)",
		},
		"close": "Close",
		"copy_info_manual": "Copy manual info",
		"copy_info_return": "Copy return info",
		"create_label": "Create label",
		"history": {
			"action": "Action",
			"actions": {
				"addcollect": "Added for collection",
				"batch_removed": "Order batch removed",
				"batch_saved": "Order batch saved",
				"order_data": "Order data updated",
				"order_manage_buyerinfo": "Buyer details updated",
				"order_marked_sent": "Order marked as sent",
				"order_updated": "Order updated",
				"started_packing": "Started packing",
				"stock_updated": "Stock updated",
				"tracking_saved": "Tracking ID saved",
				"tracking_uploaded": "Tracking ID uploaded",
			},
			"blank": "[blank]",
			"content": "Content",
			"date": "Date",
			"loading_history": "Loading history...",
			"more": "more",
			"no_history": "No history for this order",
			"user": "User",
		},
		"invoice_sent": "Tax invoice sent to {{}}",
		"load": {
			"buyerid": "Buyer ID",
			"load": "Load",
			"order": "Order ID",
			"record": "Record ID",
			"tracking": "Tracking ID",
		},
		"no": "No",
		"noone": "No one",
		"notifications": {
			"buyer_details_not_changed": "You have not made any changes to the buyer details.",
			"buyer_details_updated": "Buyer details were updated in the database.",
			"cannot_find_courier": "Error: Could not determine the courier name.",
			"cannot_get_order": "Could not retrieve order - the order may be on a store that is no longer used.",
			"cannot_load_country_codes": "Error: Could not load country code data.",
			"cannot_send_tracking_info": "Cannot send tracking information for order type 'blank'.",
			"confirm_leave_page": "Are you sure you want to leave this page? Any unsaved changes will be lost.",
			"order_info_copied": "Order info has been copied to the clipboard.",
			"order_not_changed": "You have not made any changes to the order.",
			"order_type_courier_postage_mismatch": "Error: The selected order type/courier and postage service do not match.",
			"order_updated": "Order {{ORDER_ID}} has been updated.",
			"postage_service_blank_order_status": "Error: Postage service cannot be blank for this order status.",
			"product_details_not_changed": "You have not made any changes to the order's products.",
			"product_details_updated": "Product details were updated in the database.",
			"tracking_number_blank": "Tracking number is blank.",
			"tracking_number_needed": "Please enter a tracking number before sending the tracking information.",
		},
		"order": {
			"abn": "Show ABN on invoice",
			"account_id": "Account ID",
			"buyerid": "Buyer ID",
			"collector": "Collector",
			"collectedtime": "Collected time",
			"dbid": "Database ID",
			"email": "Buyer email",
			"group": "Group ID",
			"invoice": "Transfer request",
			"message": "Buyer message",
			"modified": "Order modified",
			"notes": "Collecting notes",
			"notes_packing": "Packing notes",
			"orderid": "Order ID",
			"packedtime": "Packing last updated",
			"packer": "Packer",
			"postservice": "Postage service",
			"products": "Order products (editable)",
			"purchase_date": "Purchase date",
			"readytime": "Ready to pack time",
			"recordid": "Record ID",
			"status": "Order status",
			"type": "Order type",
			"tracking": "Tracking numbers (one per line, newest at the bottom)",
			"trackingdetails": "Open latest tracking details",
		},
		"order_history": "Order history",
		"ordernum": "Enter order/buyer/tracking ID",
		"products": {
			"name": "Product Name",
			"quantity": "Qty",
			"sku": "SKU / Product #",
		},
		"save": "Save",
		"save_invoice": "Save invoice",
		"send": "Send",
		"send_invoice": "Send invoice",
		"send_invoice_confirm": "Are you sure you want to send a tax invoice to {{}}?",
		"send_invoice_error": "Tax invoice could not be sent. Please try again.",
		"send_tracking": "Send latest tracking",
		"send_tracking_confirm": "Are you sure you want to send order tracking information to {{}}?",
		"send_tracking_error": "Tracking information could not be sent. Please try again.",
		"store": "Store",
		"tracking_sent": "Tracking information sent to {{}}",
		"yes": "Yes",
	},

	// Management
	"management": {
		"buttons": {
			"login": "Login",
			"refresh": "Refresh",
			"reset": "Reset",
			"save": "Save changes",
			"send_message": "Send message",
			"cancel": "Cancel",
		},
		"menu": {
			"main": "Main",
			"users": "Users",
			"client": "Client Configuration",
			"server": "Server Configuration",
			"messages": "Notifications",
		},
		"main": {
			"text": "Welcome to the management page",
			"password": "Enter password",
		},
		"users": {
			"newuser": "Add new user",
			"table": {
				"id": "ID",
				"username": "Username",
				"firstname": "First name",
				"lastname": "Last name",
				"password": "Password",
				"permissions": "Permissions",
				"type": "Type",
			},
			"notice1": "Every user must have a unique username and their first name cannot be blank.",
			"notice2": "Usernames must be in lower case.",
			"change": "Change selected user's password",
			"confirm_remove": "Are you sure you want to remove the selected user?",
			"remove": "Remove selected user",
			"no_users": "No users",
		},
		"messages": {
			"table": {
				"id": "ID",
				"username": "Username",
				"fullname": "Name",
			},
			"enter_message": "Enter notification message here",
			"sent": "The notification message has been sent.",
			"error": "THe notification message could not be sent.",
		},
		"password": {
			"username": "Username",
			"new": "New password",
			"confirm": "Confirm password",
			"save": "Save password",
			"set": "Set",
			"not_set": "Not set",
		},
		"notifications": {
			"username_firstname_needed": "Every user must have a username and a first name.",
			"no_users_changed": "No users have been changed.",
			"user_changes_saved": "Changes to the users have been saved into the database.",
			"no_user_selected": "No user selected.",
			"one_user_password_reset": "Only one user's password can be reset at a time.",
			"password_cannot_be_blank": "Password cannot be blank.",
			"passwords_not_same": "Please enter the same password in both fields.",
			"cannot_update_password": "Error: The selected user's password could not be updated.",
			"password_updated": "The selected user's password has been updated.",
			"one_user_remove": "Only one user can be removed at a time.",
			"cannot_remove_user": "Error: The selected user could not be removed.",
			"user_removed": "The selected user has been removed.",
			"cannot_process_config_file": "Error: Could not process configuration file.",
			"config_file_empty": "Configuration file cannot be empty.",
			"config_data_saved": "Configuration data has been saved.",
			"no_message_entered": "You have not entered a notification message to send.",
			"message_sent": "Your notification message has been sent.",
		},
	},

	// Order Stock
	"order_stock": {
		"available": {
			"title": "Available quantity",
			"amount": "Amount:",
			"update": "Update",
		},
		"button": {
			"cancel": "Cancel order",
		},
		"cancel": {
			"button": "Cancel order",
			"discontinued": "Discontinued",
			"notes": "Cancel Note:",
			"outofstock": "Out of stock",
			"reason": "Reason for cancellation:",
		},
		"confirm": {
			"title": "Confirm changes?",
			"button": "Confirm",
		},
		"discontinue": {
			"title": "Mark products as discontinued",
			"button": "Mark as discontinued",
		},
		"edit_orders": "Edit orders",
		"include_instock": "Include in stock",
		"instock": {
			"title": "Listed products will be put back in stock",
			"subtitle": "Any out of stock orders containing any of the products listed below will be sent back to collecting.",
			"footer_notes": "Orders containing other products that are still out of stock will not be sent back to collecting.",
		},
		"item_delayed": {
			"mark_delayed": "Mark product as delayed",
		},
		"item_discontinue": {
			"mark_discontinue": "Mark product as discontinued",
			"discontinue_amount": "Discontinue amount:",
		},
		"item_instock": {
			"mark_instock": "Mark product as in stock",
			"instock_amount": "In stock amount:",
			"mistake": "Mistake:",
		},
		"item_legend": "Product status legend:",
		"item_lowstock": {
			"mark_lowstock": "Mark product as low stock",
		},
		"item_order": {
			"order_amount": "Order amount:",
			"mark_ordered": "Mark product as ordered",
		},
		"item_outofstock": {
			"mark_outofstock": "Mark product as out of stock",
		},
		"item_representatives": {
			"title": "Representatives",
			"title_confirm_remove": "The selected entries will be removed",
			"search": "Search",
			"companies": "Companies",
			"representatives": "Representatives",
			"wholesalers": "Wholesalers",
			"invoices": "Invoices",
			"add": "Add row",
			"refresh": "Refresh",
			"remove": "Remove entries",
			"save": "Save changes",
			"table": {
				"add_to_sku": "Add to SKU",
				"company": "Company",
				"deal": "Deal",
				"email": "Email",
				"mobile": "Mobile",
				"name": "Name",
				"no_entries": "No entries",
				"none": "None",
				"notes": "Notes",
				"phone": "Phone",
				"search_link": "Search Link",
				"reference": "Reference",
				"wholesaler": "Wholesaler",
			},
		},
		"item_table": {
			"actions": "Actions",
			"available": "Available",
			"barcode": "Barcode",
			"cost": "Cost",
			"delayed": "Delayed",
			"discontinued": "Discontinued",
			"disc_amount": "Discontinue amount",
			"invoices": "Invoice References",
			"image": "Image",
			"instock_amount": "In stock amount",
			"instock": "In stock",
			"lowstock": "Low stock",
			"mistake": "Mistake?",
			"name": "Product Name",
			"no_orders": "No. Orders",
			"ordered": "Ordered",
			"ordered_available_needed": "Ordered / Available / Needed",
			"outofstock": "Out of stock",
			"search": "Search",
			"show_reps": "Representatives",
			"sku": "SKU",
			"status": "Status",
			"view_orders": "View orders",
		},
		"logout": "Return to Menu",
		"no_orders": "There are currently no out of stock orders.",
		"notes": {
			"save": "Save notes",
			"text": "Enter order notes...",
			"title": "Edit notes for this order",
		},
		"notifications": {
			"cannot_get_data": "Could not load data.",
			"changes_saved": "Changes have been saved.",
			"entries_removed": "The selected entries have been removed.",
			"error": "Error",
			"input_number": "Please enter a number.",
			"item_no_oos_orders": "SKU {{SKU}} currently has no out of stock orders.",
			"no_entries_changed": "No entries have been changed.",
			"no_entries_selected": "No entries have been selected.",
			"sku_not_found": "Could not find the specified SKU.",
			"update_success": "Successfully updated.",
		},
		"order_cancel": {
			"outofstock": "Out of stock",
			"discontinued": "Discontinued",
		},
		"order_number_label": "Order ID",
		"orders": {
			"cancel": "Cancel",
			"to_collect": "To collecting",
		},
		"record_entry": {
			"cancel": "Cancel order",
			"edit_notes": "Edit notes",
			"f_delivery": "Delivery:",
			"f_subtotal": "Subtotal:",
			"f_total": "Total:",
			"item_image": "Image",
			"item_name": "Product Name",
			"item_price": "Price",
			"item_quantity": "Quantity",
			"item_quantity_total": "Total Units",
			"item_sku": "SKU / Barcode",
			"item_subtotal": "Subtotal",
			"to_collect": "To collecting",
		},
		"search": "Product search:",
		"sku_add": {
			"add_sku": "Add SKU",
			"already_added": "is already in table.",
		},
		"sku_label": "SKU:",
		"sku_name": "Name:",
		"to_collect": {
			"back_to_collect": "Send to collecting",
			"confirm": "Confirm",
		},
	},

	// Packing
	"packing": {
		"awaiting_postage": "Scan a postage label to begin packing its order",
		"combined_order": "Combined order",
		"customer": {
			"id": "User ID",
			"message": "Message from buyer",
			"name": "Name",
			"company": "Company",
			"address1": "Address 1",
			"address2": "Address 2",
			"suburb": "Suburb",
			"state": "State",
			"postcode": "Postcode",
			"country": "Country",
		},
		"enter_characters": "Enter the last 4 characters of the barcode",
		"enter_order_id": "Or enter an Order ID:",
		"error_refresh": "An error has occured, please refresh.",
		"instructions": {
			"title": "Packing instructions",
			"line1": "Scan a postage label to open the associated order(s) and begin packing.",
			"line2": "Scan the barcode of each item in the order, in order to ensure the correct items and quantities are packed.",
			"line3": "If an item's barcode cannot be scanned, choose the relevant reason and mark the order to be packed later.",
			"line4": "If an item does not need to have its barcode scanned, please ensure that the correct item and quantity are packed.",
			"line5": "When all of the items have been packed, scan the postage label to mark the order as packed.",
			"line6": "If the postage label is accepted, affix the label onto the package. If the order was opened through its order ID, please ensure that the correct label is affixed onto the package.",
			"line7": "If the postage address on the screen is not in English, also affix a copy of the original address onto the package.",
			"line8": "At this stage, the order is now packed!",
		},
		"inventory": {
			"expiry": "Expiry",
			"location": "Location",
			"lot": "Lot",
			"quantity": "Quantity",
		},
		"item_barcode": "Barcode",
		"item_image": "Image",
		"item_name": "Product Name",
		"item_number": "Product #",
		"item_quantity": "Quantity",
		"item_scanned": "Scanned / Total",
		"item_sku": "SKU",
		"item_sku_barcode": "SKU / Barcode",
		"item_tbs": "Scanned / Total",
		"later": "Pack order later",
		"later_reason": {
			"title": "Pack order later reason",
			"cannot_scan_item": "Cannot scan item",
			"missing_item": "Missing order item",
			"other": "Other",
			"select_reason": "Select a reason for doing order later",
		},
		"loading": "Loading...",
		"manual_message": "Packing notes",
		"order_bucket_id": "Bucket",
		"order_date": "Date",
		"order_id": "ID",
		"order_record_number": "Record #",
		"override": "Override scanning and mark as packed",
		"ovrd": "Override: 'OVRD'",
		"stats_orders_packed": "Orders Packed",
		"stats_packer": "Packer",
		"stats_products_packed": "Products Packed",
		"submit": "Submit",
		"suggested_packaging": "Suggested packaging",
		"notifications": {
			"all_products_scanned": "All the products have already been scanned. Please ensure all products have been packed correctly and scan the label to complete the order.",
			"confirm_leave_page": "Are you sure you want to leave this page? If you have finished packing this order, then please scan the packing label in order to complete this order.",
			"incorrect_product_scanned": "An incorrect product has been scanned.",
			"product_already_scanned": "Already finished scanning {{BARCODE}}.",
			"product_barcode_not_found": "Cannot scan the following products because their barcodes could not be found. Ensure the correct amount of these products are included in the package before completion.\n\n{{PRODUCTS}}",
			"product_scan_required": "Please scan and pack all the products before completing the order.",
		},
	},

	// SKU Management
	"sku_manage": {
		"buttons": {
			"login": "Login",
			"refresh": "Refresh",
			"reset": "Reset",
			"save": "Save changes",
			"send_message": "Send message",
			"cancel": "Cancel",
		},
		"menu": {
			"search": "Search",
			"create": "Create",
		},
		"main": {
			"text": "Welcome to the management page",
			"password": "Enter password",
		},
		"users": {
			"newuser": "Add new user",
			"table": {
				"id": "ID",
				"username": "Username",
				"firstname": "First name",
				"lastname": "Last name",
				"password": "Password",
				"permissions": "Permissions",
				"type": "Type",
			},
			"notice1": "Every user must have a unique username and their first name cannot be blank.",
			"notice2": "Usernames must be in lower case.",
			"change": "Change selected user's password",
			"confirm_remove": "Are you sure you want to remove the selected user?",
			"remove": "Remove selected user",
			"no_users": "No users",
		},
		"messages": {
			"table": {
				"id": "ID",
				"username": "Username",
				"fullname": "Name",
			},
			"enter_message": "Enter notification message here",
			"sent": "The notification message has been sent.",
			"error": "THe notification message could not be sent.",
		},
		"password": {
			"username": "Username",
			"new": "New password",
			"confirm": "Confirm password",
			"save": "Save password",
			"set": "Set",
			"not_set": "Not set",
		},
		"notifications": {
			"username_firstname_needed": "Every user must have a username and a first name.",
			"no_users_changed": "No users have been changed.",
			"user_changes_saved": "Changes to the users have been saved into the database.",
			"no_user_selected": "No user selected.",
			"one_user_password_reset": "Only one user's password can be reset at a time.",
			"password_cannot_be_blank": "Password cannot be blank.",
			"passwords_not_same": "Please enter the same password in both fields.",
			"cannot_update_password": "Error: The selected user's password could not be updated.",
			"password_updated": "The selected user's password has been updated.",
			"one_user_remove": "Only one user can be removed at a time.",
			"cannot_remove_user": "Error: The selected user could not be removed.",
			"user_removed": "The selected user has been removed.",
			"cannot_process_config_file": "Error: Could not process configuration file.",
			"config_file_empty": "Configuration file cannot be empty.",
			"config_data_saved": "Configuration data has been saved.",
			"no_message_entered": "You have not entered a notification message to send.",
			"message_sent": "Your notification message has been sent.",
		},
	},

	// Statistics
	"statistics": {
		"buttons": {
			"load": "Load",
		},
		"menu": {
			"choose_store": "Choose store:",
			"packing": "Packing statistics",
		},
		"packing": {
			"date": "Date:",
			"table": {
				"name": "Name",
				"orders_packed": "Orders Packed",
				"products_packed": "Products Packed",
				"weight_packed": "Weight Packed (kg)",
			},
			"no_packers": "No orders were packed on this day.",
		},
		"notifications": {
			"cannot_load_pack_stats": "Error: Could not load packing statistics.",
		},
	},

	// Stock Update
	"stockupdate": {
		"clear": "Clear",
		"confirm": "Confirm",
		"confirm_change": "Confirm changes?",
		"item": {
			"actions": "Actions",
			"available_amount": "Available",
			"disc_amount": "Set amount",
			"image": "Image",
			"name": "Product Name",
			"invoice_references": "Invoice References",
			"req_amount": "Required",
			"search": "Search",
			"status": "Status",
			"stock": "Stock",
			"update_time": "Update Time",
		},
		"logout": "Back to Menu",
		"no_items": "There are no items to update.",
		"notifications": {
			"error": "Error",
		},
		"search": "Search",
		"sku": "SKU / Barcode",
	},

	// API
	"api": {
		// General
		"GENERAL_NO_PERMISSION": "You are not able to perform this action.",
		"GENERAL_CANNOT_GET_CONVERSION_DATA": "Could not retrieve conversion data.",
		"GENERAL_CANNOT_COMMIT_TRANSACTION": "Could not commit transaction.",
		"GENERAL_CANNOT_UPDATE_DB": "Could not update data in the database.",
		"GENERAL_INVALID_DATA": "Invalid data.",
		"GENERAL_INVALID_DATA_PROVIDED": "Invalid data provided.",
		"GENERAL_INVALID_ORDER_DATA": "Invalid order data.",
		"GENERAL_INVALID_STORE": "Invalid store.",
		"GENERAL_NO_CHANGES": "No changes made.",
		"GENERAL_NO_DATA": "No data available.",
		"GENERAL_NO_ORDERS": "No orders.",
		"GENERAL_NOT_LOGGED_IN": "Not logged in.",
		"GENERAL_POSTAGE_PRICES_MISSING": "Postage price data is missing in the configuration.",
		"GENERAL_SERVICE_UNAVAILABLE": "Service unavailable",
		"GENERAL_UNSUPPORTED_METHOD": "Unsupported method.",
		"GENERAL_WRONG_METHOD": "Wrong method",
		// Analytics
		"ANALYTICS_INVALID_DATE_RANGE": "Invalid order date range.",
		"ANALYTICS_NO_SKUS": "No SKUs.",
		// Brands
		"BRANDS_NO_BRANDS": "No brands listed in the database.",
		// Config
		"CONFIG_PASSWORD_NOT_PROVIDED": "Password was not provided.",
		"CONFIG_WRONG_PASSSWORD": "Incorrect password.",
		"CONFIG_INVALID_CONFIG": "Invalid config type.",
		"CONFIG_CANNOT_DETERMINE_KEY": "Could not determine config key.",
		"CONFIG_CANNOT_LOAD_DATA": "Could not load configuration data.",
		"CONFIG_INVALID_CONFIG_DATA": "Invalid configuration data.",
		"CONFIG_CANNOT_SAVE_DATA": "Could not save configuration data.",
		// Email sender
		"EMAILS_INVALID_EMAIL_DETAILS": "Invalid email details.",
		"EMAILS_INVALID_EMAIL_TYPES": "Invalid email type.",
		"EMAILS_CANNOT_SEND_EMAIL": "Could not send email.",
		// Get record
		/*"GET_RECORD_INVALID_STORE_ID": "Invalid store/record ID.",
		"GET_RECORD_ERROR_SENDING_REQUEST": "Error sending request.",
		"GET_RECORD_ERROR_SENDING_REQUEST_2": "Error sending request (2).",
		"GET_RECORD_SALES_RECORD_NOT_FOUND": "Sales record ID not found.",*/
		// Items
		"ITEMS_CANNOT_CHANGE_STATUS": "Status cannot be changed or has already been changed to the selected status.",
		"ITEMS_DETAILS_NOT_FOUND": "Product details for this order could not be found in the database.",
		"ITEMS_INVALID_PACKAGING_DATA": "Invalid packaging data provided.",
		"ITEMS_INVALID_SKU": "Invalid SKU.",
		"ITEMS_INVALID_SKU_DATA": "Invalid SKU data.",
		"ITEMS_INVALID_STATUS": "Invalid status.",
		"ITEMS_INVALID_STATUS_PROVIDED": "Invalid status provided.",
		"ITEMS_LOCATION_NOT_ALLOWED": "The specified item location is not allowed.",
		"ITEMS_NO_CHANGES_ENTRY_TIME": "No changes made to the entry time.",
		"ITEMS_NO_CHANGES_MADE": "There were no changes to be made.",
		"ITEMS_NO_CHANGES_PACKAGING": "No changes made to the packaging information.",
		"ITEMS_NO_CHANGES_UPDATE_TIME": "No changes made to the update time.",
		// Manage
		"MANAGE_CANNOT_SAVE_DATA": "Could not save tracking data into the database.",
		"MANAGE_ORDER_ID_REQUIRED": "Order ID is required.",
		"MANAGE_ORDER_ID_NOT_VALID": "Order ID is not valid.",
		"MANAGE_STORE_REQUIRED": "Store is required.",
		"MANAGE_CANNOT_FIND_ID": "Cannot find the specified order in the database.",
		"MANAGE_STORE_DISABLED": "The specified ID is from a store that has been disabled in the system.",
		"MANAGE_INVALID_QUANTITY": "At least one product must have a quantity greater than zero.",
		// Orders - Download
		"ORDERS_DOWNLOAD_CANNOT_ADD_DB": "Could not add data into the database.",
		"ORDERS_DOWNLOAD_CANNOT_ADD_DB_WOOCOMMERCE": "Could not add WooCommerce order data into the database.",
		"ORDERS_DOWNLOAD_CANNOT_ADD_DB_WOOCOMMERCE_LOCATIONS": "Could not add WooCommerce product location data into the database.",
		"ORDERS_DOWNLOAD_NOT_ALLOWED": "The ability to download orders has been disabled for one or more of the selected stores.",
		"ORDERS_DOWNLOAD_ERROR_OCCURRED": "An error has occurred. Please try again or contact IT Support.",
		"ORDERS_DOWNLOAD_NO_AMAZON_ORDERS": "There are currently no new Amazon orders.",
		"ORDERS_DOWNLOAD_NO_EBAY_ORDERS": "There are currently no new eBay orders.",
		"ORDERS_DOWNLOAD_SERVICE_NOT_SUPPORTED": "Service not supported.",
		// Orders - Mark sent
		"ORDERS_MARK_SENT_NO_UNSENT": "No unsent orders.",
		"ORDERS_MARK_SENT_NOT_EXIST_OR_MARKED_SENT": "Orders do not exist in the database or they have already been marked as sent.",
		"ORDERS_MARK_SENT_PARTIALLY": "Some orders could not be or have already been marked as sent",
		// Orders - Add
		"ORDERS_ADD_MISSING_ADDRESS_DETAILS": "One or more orders has missing address details. Please make sure to save any changes you have made.",
		"ORDERS_ADD_CANNOT_ADD_COLLECTING": "Could not add orders to the collecting table in the database.",
		// Orders - Batch
		"ORDERS_BATCH_INVALID_IDS": "Order IDs are missing or not valid.",
		"ORDERS_BATCH_CANNOT_SAVE": "Could not save batch.",
		"ORDERS_BATCH_NO_SAVED_BATCH": "There is currently no saved batch.",
		// Orders - Get
		"ORDERS_GET_CANNOT_ADD_DB": "Could not add entry to database.",
		"ORDERS_GET_CANNOT_UPDATE_DB": "Could not update orders in the database.",
		// Orders - Load
		"ORDERS_LOAD_PROCESSING_INTERNAL_DATA": "The system is currently processing internal data and is unavailable. Please try again after 30 seconds.",
		"ORDERS_LOAD_INVALID_STATUS": "Invalid order status.",
		"ORDERS_LOAD_INVALID_DATE": "Invalid date.",
		"ORDERS_LOAD_INVALID_END_DATE": "Invalid end date.",
		// Orders - Remove
		"ORDERS_REMOVE_ORDER_NOT_EXIST": "Orders do not exist in the database or have already been deleted.",
		// Orders - Update
		"ORDERS_UPDATE_INVALID_DATA": "Data is not valid.",
		"ORDERS_UPDATE_INVALID_ORDER_ID": "Order ID is not valid.",
		"ORDERS_UPDATE_INVALID_TRACKING_ID": "Invalid tracking ID for order {{}}.",
		"ORDERS_UPDATE_INVALID_TRACKING_DATA": "Invalid tracking data for order ({{}}).",
		"ORDERS_UPDATE_INVALID_TRACKING_DATA_DB": "Invalid tracking data is present in the database for one or more orders.",
		"ORDERS_UPDATE_INVALID_STATUS": "Order status is not valid.",
		"ORDERS_UPDATE_INVALID_GROUP_ID": "Group ID is not valid.",
		"ORDERS_UPDATE_INVALID_BATCH_NUM": "Batch number is not valid.",
		"ORDERS_UPDATE_INVALID_POSTAGE_SERVICE": "Postage service is not valid.",
		"ORDERS_UPDATE_POSTAGE_SERVICE_REQUIRED": "Postage service is required for this order status.",
		"ORDERS_UPDATE_ORDER_MISSING_DB": "One or more orders are missing from the collecting table.",
		"ORDERS_UPDATE_INVALID_DB_ORDER_DATA": "Database order data is invalid.",
		"ORDERS_UPDATE_COULD_NOT_UPDATE_ORDER": "Could not update one or more orders.",
		"ORDERS_UPDATE_COULD_NOT_UPDATE_ORDER_2": "Could not update one or more orders. The order status for all of the orders must be '{{}}' but the following order currently has a different status: {{}}",
		"ORDERS_UPDATE_NO_CHANGES_MADE": "No changes made. The order(s) may have already been changed by someone else or there was no valid order data to be changed.",
		// Packing
		"PACKING_NO_TRACKING": "This order does not have a tracking ID and cannot currently be packed.",
		"PACKING_NOT_AWAITING": "This order is not awaiting to be packed.",
		"PACKING_NOT_AWAITING_FOUND": "This order is not awaiting to be packed or an order could not be found for this tracking ID or order ID.",
		"PACKING_NOT_FOUND": "Could not find an order for this tracking ID or order ID.",
		"PACKING_ORDER_ALREADY_IN_PROGRESS": "You have already started packing another order and it is currently in progress. Please complete the following order before packing another order: {{}}",
		"PACKING_ORDERID_INCORRECT_FORMAT": "Invalid order ID entered.",
		"PACKING_TRACKING_INCORRECT_FORMAT": "Invalid tracking ID entered.",
		"PACKING_TRACKING_NO_MATCH": "Tracking ID does not match.",
		// Representatives
		"REPRESENTATIVES_INVALID_SKU_DATA": "Invalid SKU data.",
		"REPRESENTATIVES_INVALID_REPRESENTATIVES": "Invalid data for representatives.",
		"REPRESENTATIVES_INVALID_WHOLESALERS": "Invalid data for wholesaler.",
		"REPRESENTATIVES_INVALID_COMPANIES": "Invalid data for companies.",
		"REPRESENTATIVES_INVALID_INVOICES": "Invalid data for invoices.",
		// Tracking
		"TRACKING_CANNOT_ADD_TRACKING": "Could not add tracking IDs to the database.",
		"TRACKING_CANNOT_CREATE_CONSIGNMENT": "Could not create consignment.",
		"TRACKING_CANNOT_CREATE_CONSIGNMENT_SOME_ORDERS": "Could not create consignment for one or more orders.",
		"TRACKING_CANNOT_SAVE_DATA": "Could not save tracking data into the database.",
		"TRACKING_CANNOT_UPDATE_DB": "Could not update database entries.",
		"TRACKING_CANNOT_UPDATE_ORDERS": "Could not update orders in the database.",
		"TRACKING_DATA_NOT_VALID": "Tracking data is not valid.",
		"TRACKING_INVALID_DATA_DB": "Invalid tracking data is present in the database for one or more orders.",
		"TRACKING_INVALID_DATA_ORDERS_DB": "Invalid tracking data is present in the database for the given orders.",
		"TRACKING_NO_ORDERS_PROCESSED": "No orders were processed.",
		"TRACKING_ORDERS_NOT_EXIST_OR_UPLOADED": "Orders do not exist in the database or their tracking details have already been uploaded.",
		"TRACKING_ORDERS_UPLOADED_MARKED_SENT_PARTIALLY": "Tracking details for orders were uploaded successfully, but some orders could not be or have already been marked as sent.",
		"TRACKING_ORDERS_UPLOADED_NOT_MARKED_SENT": "Tracking details for orders were uploaded successfully but could not be marked as sent.",
		"TRACKING_ORDERS_UPLOADED_PARTIALLY": "Tracking details for some orders could not be uploaded or have already been uploaded.",
		"TRACKING_SERVICE_NOT_SUPPORTED": "Service not supported.",
		// Users
		"USERS_PASSWORD_MISSING": "Password was not provided.",
		"USERS_WRONG_PASSWORD": "Incorrect password.",
		"USERS_NO_USERS": "No users.",
		"USERS_INVALID_TOKEN": "Invalid user token.",
		"USERS_USER_NOT_EXIST": "The username you have entered does not exist or is not active.",
		"USERS_INCORRECT_PASSWORD": "The password you have entered is incorrect.",
		"USERS_CANNOT_SAVE_PASSWORD": "Could not save user password.",
		"USERS_CANNOT_CREATE_PASSWORD_HASH": "Could not create password hash.",
		"USERS_MISSING_INPUT_DATA": "Missing input data.",
		"USERS_MISSING_USERNAME_FIRSTNAME": "Username and/or first name is missing for one or more users.",
		"USERS_CANNOT_SAVE_SESSION": "Could not save user session.",
		"USERS_INVALID_USER_DATA": "Invalid user data.",
		"USERS_INVALID_USER_DATA_PARAMETER": "Invalid user data parameter.",
		"USERS_USER_NOT_EXIST_DB": "User does not exist in the database.",
		"USERS_REMOVE_ONE_USER_AT_TIME": "Only one user can be removed at a time.",
		"USERS_USER_ID_NOT_PROVIDED": "No user IDs supplied.",
		"USERS_CANNOT_REMOVE_USER": "Could not remove user from the database.",
		"USERS_USER_NOT_EXIST_DELETED_DB": "User does not exist in the database or has already been deleted.",
		"USERS_CANNOT_ADD_ENTRY_DB": "Could not add entry to database.",
		"USERS_CANNOT_UPDATE_USERS_DB": "Could not add/update users in the database.",
	},
}

// Notification bar

export default class NotificationBar {
	constructor(options = {}) {
		this.initialised = false;
		this.main = null;
		this.notification = null;
		this.options = options;
		this.timer = null;
		this.visible = false;

		this.hide = this.hide.bind(this);

		if (document.readyState == 'loading') {
			window.addEventListener('DOMContentLoaded', this.initialise.bind(this), {once: true});
		}
		else {
			this.initialise();
		}
	}

	initialise() {
		if (this.initialised) return;
		this.initialised = true;
		this.main = document.getElementById('main') || document.getElementById('home');
		this.notification = document.getElementById('notification');
		this.notification.className = 'hide';

		if (this.options.positionFixed) {
			this.notification.className += ' fixed';
		}

		if (!this.notification) {
			console.error('Notification element is missing.');
			return;
		}

		let content = document.createElement('div');
		content.className = 'content';
		this.notification.appendChild(content);

		let close = document.createElement('div');
		close.className = 'close';
		this.notification.appendChild(close);

		let stylesheet = document.createElement('style');
		stylesheet.innerHTML = NOTIFICATION_CSS;
		document.head.appendChild(stylesheet);

		this.notification.addEventListener('click', (e) => e.stopPropagation(), false);
		this.notification.querySelector('.close').addEventListener('click', this.hide, false);
		this.hide();
	}

	show(text, options = {}) {
		options = Object.assign({background: null, hide: 4000}, options.constructor === Object ? options : {});
		if (options.dontOverlap && this.visible) return;

		// Show notification
		let content = this.notification.querySelector('.content');
		this.visible = true;
		content.textContent = text ? text.trim() : '';
		content.innerHTML = content.textContent.replace(/\n/g, '<br>');

		this.notification.className = options.background || '';
		if (this.options.positionFixed) {
			this.notification.classList.add('fixed');
		}

		setTimeout(() => {
			this.notification.classList.add('show');
			this.main.classList.add('notification');
		}, 5);

		// Hide the notification
		if (this.timer) clearInterval(this.timer);
		if (options.hide && !isNaN(options.hide)) {
			this.timer = setTimeout(this.hide, options.hide);
		}
	}

	hide() {
		if (this.timer) clearInterval(this.timer);
		this.timer = null;
		this.visible = false;

		this.main.classList.remove('notification');
		this.notification.classList.remove('show');
		this.notification.classList.add('hide');
	}
}


const NOTIFICATION_CSS = `
#notification {
	flex: 0 0 auto;
	position: relative;
	box-sizing: border-box;
	font-size: 16px;
	background-color: #bcddff;
	border-top: 1px solid #555;
	padding: 4px 35px 4px 8px;
	width: 100%;
	min-height: 80px;
	opacity: 0;
	overflow-x: hidden;
	overflow-y: auto;
	transition: opacity 400ms;
	z-index: 100;
}
#notification.fixed {
	position: fixed;
	bottom: 0;
}
#notification .content {
	height: 71px;
}
#notification.show {
	opacity: 1;
}
#notification.hide {
	display: none;
}

/* Close button */
#notification > .close {
	position: absolute;
	padding: 10px;
	top: 0;
	right: 0;
	width: 25px;
	height: 25px;
	cursor: pointer;
	transition: transform .25s ease-in-out;
}
#notification > .close:hover {
	transform: rotate(180deg);
}
#notification > .close::before, #notification > .close::after {
	position: absolute;
	display: block;
	content: "";
	border-top: 2px solid rgba(0,0,0,0.3);
	margin: auto;
	top: 8px;
	bottom: 8px;
	left: 8px;
	right: 8px;
	height: 0;
	transform: rotate(45deg);
	transform-origin: center;
}
#notification > .close::before {
	transform: rotate(45deg);
}
#notification > .close::after {
	transform: rotate(-45deg);
}
#notification > .close:hover::before, #notification > .close:hover::after {
	border-top-color: #000;
}
#notification > .close.scroll {
	right: 17px;
}
`;
